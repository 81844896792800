@import '../../../styles/variables.scss';

.font {
    font-family: #{$lexend-deca};
    color: #{$color3};
}

.bgnd {
    width: 355px;
    height: 355px;
    background-image: radial-gradient(circle, #FFCB94, #FFCB94);
    background-size: cover;
    box-shadow: 0 0 60px 45px #FFCB94;
    opacity: 0.2;
}

.top-logo{
  z-index: 0;
  top: 70px;
}

.bottom-logo{
  z-index: 0;
  bottom: 110px;
}

.flow {
  overflow-y: hidden;
}

.res-btn {
  width: 100%;
}

.scrolling-text {
    display: inline-block;
    animation: scrollText 2s ease-in-out infinite;
  }
  
  @keyframes scrollText {
    0% {
      transform: translateY(100%);
    }
    25% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  .show-mb{
    display: none;
  }

  .hide-mb {
    display: block;
  }

  .res {
    width: 90%;
  }

  // @media (min-width:1600px){
  //   .res-btn{
  //     width: 80%;
  //   }
  // }

  .ev {
    pointer-events: none;
  }


  @media(max-width:1275px) {
    .res {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;

      .first{
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        width: 100% !important;

        p{
          text-align: center !important;
        }

        .small-screen-align {
          display: flex;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: center !important;
        }

        .res-btn {
          display: flex;
          flex-direction: column;
          justify-content: center !important;
          align-items: center !important;
          align-self: center !important;
          width: 100% !important;
        }
      }

      .second{
        width: 100% !important;
        margin-top: 40px;
      }
    }
  }

  .h1-size {
    font-size: 32px;
  }

  .p-size {
    font-size: 14px;
  }

  @media (max-width:768px){
    .show-mb {
      display: block;
    }

    .hide-mb {
      display: none;
    }
  }


  @media (max-width:500px) {
    .h1-size {
      font-size: 22px !important;
    }

    .cent {
      text-align: center;
    }
  }

  @media(max-width:420px){
    .top-logo{
        top: 46px !important;
    }
    .bottom-logo{
        bottom: 85px !important;
    }
  }



  @media (min-width:1550px){
    .res {
      width: 70% !important;
      align-self: center;
    }
  }

  @media(min-width:2500px) {
    .res {
      width: 45% !important;
    }
  }

  @media(min-width:3500px) {
    .res {
      width: 40% !important;
    }
  }

  @media(min-width:4500px) {
    .res {
      width: 30% !important;
    }
  }

  @media(min-width:5000px) {
    .res {
      width: 25% !important;
    }
  }