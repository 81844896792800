.process-steps{
    width: 80%;
}

@media(min-width:1550px){
    .process-steps{
        width:60%;
    }
}

@media(max-width:600px){
    .process-steps{
        width: 100%;
    }
}

@media(max-width:390px){
    .process-steps{
        padding: 20px 0 20px 0;
    }
}