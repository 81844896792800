@import '../../../styles/variables.scss';

custom-width {
    width: 50%;
}
.collapser-menu{
    width: 90%;
}

.collapser-nav{
    width: 50%;
    margin: 10px 10px 10px -5px;
}

.collapser-menu-items{
    cursor: pointer;
}

.mob-collapser-nav{
    display: none;
}

.collapser-menu-data{
    width: 90%;
    margin-top: -60px;
}

.collapser-menu-widget{
    width: 50%;
    position: relative;
}

.collapser-menu-content{
    width: 100%;
    margin-bottom: -50px;
    position: sticky;
    top: 60px;
    background-color: #ffffff;
    left: -30px;
}

.pr-top{
    width: 100%;
    position: relative;
    left: 30px;
    border-left: 2px dotted rgba(#e19703, 0.2);
    padding: 100px 100px 100px 30px;
}

.collapser-head-left{
    padding: 15px;
    border-radius: 15px;
    background: #EBF6EB;
    margin-left: -78px;
}

.collapser-head-right{
    padding: 15px;
    border-radius: 15px;
    background: #EBF6EB;
    margin-right: -78px;
}

.mob-collapser-head{
    display: none !important;
    padding: 10px 7px;
    border-radius: 15px;
    background: #EBF6EB;
    margin-left: -55px;
    width: 100%;
    gap: 120px;
}

.menu-components-wrapper{
    width: 100%;
    margin-bottom: 50px;
    scroll-behavior: smooth;
}

.each-collapser{
    width: 100%;
    background-color: #ffffff;
    z-index: -10;
    padding-top: 50px;
    transform-origin: top center;
    position: relative;
}

.collapser-menu-title{
    width: 100%;
    padding: 20px;
}

.sticky-collapser-bar{
    background-color: #ffffff; 
    box-shadow: 0 2px 5px -2px rgb(228 228 228); 
    position: sticky;
    top: 70px;
    width:100%;
    padding:12px 10px 10px 10px;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

// .flex-row {
//     flex-direction: row !important;
// }

@media(min-width:1550px){
    .collapser-menu{
        width: 70%;
    }
    .collapser-menu-data{
        width: 70%;
    }
}

@media(min-width:2500px) {
    .sticky-collapser-bar{
        width: 45% !important;
    }
    .collapser-menu-data{
        width: 45% !important
    }
    .collapser-nav{
        width: 100% !important;
    }
}

@media(min-width:3500px) {
    .sticky-collapser-bar{
        width: 40% !important;
    }
    .collapser-menu-data{
        width: 40% !important
    }
}

@media(min-width:4500px) {
    .sticky-collapser-bar{
        width: 30% !important;
    }
    .collapser-menu-data{
        width: 30% !important
    }
}

@media(min-width:5000px) {
    .sticky-collapser-bar{
        width: 25% !important;
    }
    .collapser-menu-data{
        width: 25% !important
    }
}

@media(max-width:1200px){
    .pr-top{
        flex-direction: column !important;
    }
    .collapser-menu-widget{
        justify-content: center !important;
    }
    .collapser-menu-title{
        width: 100%;
        align-items: center !important;
        text-align: center;
    }
    .collapser-menu-widget{
        width: 50%;
        padding: 30px 0 30px 0;
    }
    .collapser-nav{
        width: 100%;
    }
    .mob-collapser-head{
        display: flex !important;
        margin-left: -100px;
    }
    .collapser-head{
        display: none !important;
    }
}

.slider-head {
    width: 90%;
    margin: 0 auto;
    text-align: left; /* Text aligned to left on large screens */
 }
 .section3{
    margin-top: 100px;
    margin-bottom: 120px;
 }
  
  /* For smaller screens (less than 860px), remove left margin and padding */
  @media (max-width: 860px) {
    .slider-head {
      margin-left: 0; /* Remove left margin */
      padding-left: 0; /* Remove left padding */
      padding-right: 0; /* Remove right padding */
      text-align: center; /* Align text in the center */
      width: 100%; /* Make width 100% on smaller screens */
    }
    
    .mob-collapser-nav{
        display: block;
        width: 100%;
        text-align: center;
    }
    .collapser-menu-items{
        display: none;
    }
    .collapser-menu-widget{
        width: 60%;
    }
    .sticky-collapser-bar{
        top: 62px;
    }
}

@media(max-width:550px){
    .collapser-menu-widget{
        width: 80%;  /* Increased width to take more space on mobile */
        padding: 0px 0; /* Reduced padding for better use of space */
    }
    // .mob-collapser-nav{
    //     display: flex;
        
    // }
    .collapser-head{
        font-size: 14px;
        // width: 100%;  /* Increase the image size to fill more space */
        // max-width: 100%;
    }
    .collapser-menu-content img{
        width: 100%;  /* Increase the image size to fill more space */
        max-width: 100%; /* Ensure the image scales well */
        height: 220px;
        max-height: 100%;
        object-fit: cover; /* Maintain the aspect ratio and ensure the image covers the container */
    }
    

}
@media (min-width: 860px) {
    .payroll-nav {
      width: 50%; /* Apply 50% width only on screens larger than 860px */
    }

}