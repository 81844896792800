@import '../../../styles/variables.scss';

.footer-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #212833;
    width:100%;
    padding-top: 100px;
}

.size-container {
    display: flex;
    flex-direction: column;
    width:98%;
    align-items: center;
    align-self: center;
}

.logo-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:90%;
    margin-bottom: 30px;
}

.first-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:50%;
}

.second-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%;
}

.logo2{
    width: 65%;
    height:45px;
}

.language-container {
    width: 40%;
    height:48px;
    border: 1px solid #909396;
    border-radius: 12px;
    color:#FFFDFA;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-left: 30px;
}

.icon-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
}

.icon{
    font-size: 20px;
    color:#FFFDFA;
}

.lang {
    font-size: 16px;
}

.img1{
    width:22%;
}

.img2{
    width:22%;
    margin-left: 30px;
}

.slogan {
    color:#00B2A9;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 13px;
    margin-left: 75px;
    align-self: flex-start;
}

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
}

.footer-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    list-style-type: none;
    padding-left: 0px;
}

.topic {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    color: #FFFFFF;
    opacity: 1 !important;
}

.footer-list {
    font-size: 12px;
    font-weight: 400;

    li {
        color: #FFFFFF;
        opacity: 0.5;
        line-height: 19.2px;
        margin-bottom: 12px;
        font-family: #{$lexend-deca};

        a {
            color: #FFFFFF;
            text-decoration: none;
        }
    }
}

.i-color {
    fill: #FFFFFF;
    opacity: 0.5;
}

.no-op {
    opacity: 1 !important;
    margin-top: 40px;
}

.para {
    color: #909396;
    font-size: 12px;
    line-height: 15px;
    align-self: flex-start;
    font-family: #{$lexend-deca};
}

.para-link{
    text-decoration: none;
    color: #909396;
}

.footer-line {
    border:0.5px solid rgba(144, 147, 150, 0.4);
    width:100%;
    padding: 0;
}

.bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.footer-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:20px;
}

.svg-size {
    height: 25px;
    width: 25px;
}


@media (max-width:992px){
    .footer-content {
        justify-content: start !important;
        flex-wrap: wrap;
    }

    .footer-list {
        width: 25%;
    }

    .language-container {
        height: 38px;
        width: 45%;
    }

    .contact-us-cont {
        width:70% !important;
    }

    .time-attendance-cont{
        width: 30% !important;
    }

    .company-cont{
        width: 20% !important;
    }
}

@media (min-width:769px) and (max-width:860px){
    .bottom-container{
        margin-bottom: 55px !important;
    }
}

@media (max-width:768px){

    .footer-list {
        width: 30%;
    }

    .bottom-container{
        flex-direction: column !important;
        align-items: center !important;
        text-align: center;
        margin-bottom: 30px;
    }

    .logo-container{
        justify-content: flex-start !important;
        flex-wrap: wrap;
    }

    .first-container{
        width: 100%;
        justify-content: space-between !important;
    }

    .second-container{
        margin-top: 20px;
        width: 100%;
        justify-content: space-between !important;
    }

    .time-attendance-cont{
        width: 40% !important;
    }

    .company-cont{
        width: 30% !important;
    }

    .bottom-container{
        padding-bottom: 50px !important;
    }
}

@media (max-width:576px){

    .footer-list {
        width: 50%;
    }

    .contact-us-cont {
        width:100% !important;
        margin: 0;
    }

    .core-hr-cont {
        width: 55% !important;
    }

    .payroll-cont {
        width: 55% !important;
    }

    .time-attendance-cont {
        width: 45% !important;
    }

    .company-cont {
        width: 45% !important;
    }

    .svg-size {
        height: 20px;
        width: 20px;
    }

    .icon{
        font-size: 16px;
    }

    .lang {
        font-size: 12px;
    }

    .img1{
        width:30%;
    }

    .img2{
        width:30%;
    }

    .no-op{
        margin-top: 2px;
    }

    .bottom-container{
        padding-bottom: 30px !important;
    }
}

@media (max-width:390px){
    .core-hr-cont {
        width: 50% !important;
    }

    .payroll-cont {
        width: 50% !important;
    }

    .time-attendance-cont {
        width: 50% !important;
    }

    .company-cont {
        width: 50% !important;
    }

    .bottom-container{
        padding-bottom: 45px !important;
    }
}

@media (min-width:380px) and (max-width:391px){
    .bottom-container{
        padding-bottom: 50px !important;
    }
}

@media (min-width:860px){
    .bottom-container{
        margin-bottom: 8px !important;
    }
}

@media (min-width:1550px){
    .size-container{
        width: 75%;
    }
}

@media(min-width:2500px) {
    .size-container{
        width: 45%;
    }
  }

  @media(min-width:3500px) {
    .size-container{
        width: 40%;
    }
  }

  @media(min-width:4500px) {
    .size-container{
        width: 30%;
    }
  }

  @media(min-width:5000px) {
    .size-container{
        width: 25%;
    }
  }