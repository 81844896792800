@import '../../../styles/variables.scss';

.outer-workforce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #{$bgcl1};
    padding: 80px 0 80px 0;
}

.inner-workforce {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.workforce-content {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.feature-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    // flex-wrap: wrap;
    width: 70%;
    list-style-type: none;
    padding-left: 0px;

    span {
        margin-right: 10px;
    }

    li {
        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
        color: #{$color5};
        margin-bottom: 20px;
        font-family: #{$lexend-deca};
    }
}

.space-between-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.make-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


@media(max-width:1275px) {
    .inner-workforce {
        flex-direction: column !important;
    }

    .workforce-content{
        width:100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        order: 0;
    }

    .mobile-img {
        width: 90%;
        align-self: center;
        order: 1;
        margin-top: 100px;
    }

    .feature-list {
        align-items: center;
    }

}


@media(max-width:768px){
    .feature-list{
        width: 100%;

        li{
            font-size: 12px;
        }
    }

    .mobile-img {
        width: 100%;
        align-self: center;
        margin-top: 50px;
    }
}

@media(max-width:576px){

    .mobile-img {
        margin-top: 50px;
    }
}


@media (min-width:1550px) {
    .inner-workforce {
        width: 70%;
    }
}


@media(min-width:2500px) {
    .inner-workforce {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .inner-workforce {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .inner-workforce {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .inner-workforce {
        width: 25%;
    }
}