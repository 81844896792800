@import '../../../styles/variables.scss';

.journey-cont {
    background-color: #FFFBF2;
    padding: 15px;
    height: 200px;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.outer-journey-cont {
    width: 90%;
}

.max-h {
    max-height: 180px;
}

.journey-slide {
    width: 80%;
    overflow-x: hidden;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
}

.journey-slide-item {
    min-width: 272px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.outer-journey-slide {
    width: 80%;
}

@media (max-width:1450px) {
    .journey-slide {
        width: 85%;
    }
}

@media (max-width:1350px) {
    .outer-journey-cont {
        flex-direction: column !important;
        justify-content: center !important;
    }

    .journey-cont {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        margin-top: -20px;
    }

    .outer-journey-slide   {
        width: 100%;
        margin-top: 20px;
    }

    .journey-slide {
        width: 100%;
    }

    .max-h {
        max-height: 380px;
    }
}

@media(max-width:768px){
    .journey-cont {
        width: 80%;
    }
}

@media(max-width:576px){
    .journey-cont {
        width: 100%;
    }

    .journey-slide-item {
        min-width: 266px;
    }
}

@media (min-width:1550px) {
    .outer-journey-cont {
        width: 70%;
    }
}


@media(min-width:2500px) {
    .outer-journey-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .outer-journey-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .outer-journey-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .outer-journey-cont {
        width: 25%;
    }
}