@import '../../../styles/variables.scss';

.all-blogs-cont {
    width: 90%;
}

.prev-btn {
    border: none;
    outline: none;
    cursor: pointer;
    height: 30px;
    width: 80px;
}

@media (min-width:1550px) {
    .all-blogs-cont {
        width: 70%;
    }
}

@media(min-width:2500px) {
    .all-blogs-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .all-blogs-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .all-blogs-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .all-blogs-cont {
        width: 25%;
    }
}