@import '../../../../styles/variables.scss';

#orange{
    rect{
        fill: #FF5722;
    }
}

.geo-merits{
    width: 100%;
    background-image: linear-gradient(to right, #FEF4EA, #fffaf5);
}
