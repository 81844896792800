@import '../../../styles/variables.scss';

.form-bg {
    background-color: #F8FAFC;
    width: 40%;
    box-shadow: 0 0 1px 1px rgb(0, 0, 0, 0.1);
}
.big-star-demo{
right: 15px !important;
top:20px !important;
}
.small-star-demo{
right: 3px !important;
top: 45px !important;
}
.inputs {
    border-radius: 12px;
    border:1px solid #CBD5E1;
    padding:10px;
    cursor: pointer;
    outline: none;
    width:95%;
    background-color: #FFFFFF;
    margin: 15px 0;
    color:#090914;
    font-weight: 400;
    font-family: #{$lexend-deca} !important;
}
.combined-word-demo{
    white-space: nowrap;
    font-size: 32px;
}

.inputs::placeholder{
    color: #090914;
    font-size: 14px;
    font-weight: 300;
    font-family: #{$lexend-deca};
}

.demo-btn {
    color: #ffffff;
    border:none;
    padding: 10px 20px;
    outline: none;
}

.terms {
    color:#18181B;
}

.place-up {
    top:10px;
    background-image: linear-gradient(to bottom, #FEF4EA, #ffffff);
    padding: 0 3px;
    font-size: 12px !important;
    color: var(--wz-secondary) !important;
}

.place-down {
    font-size: 14px;
    top:32px;
}
.discover-text{
    margin-bottom: 50px;
}
.show {
    display: block;
}

.hide {
    display: none;
}

#label {
    pointer-events: none;
}

    
    @media (max-width:1375px){
        .big-star-demo{
            right: -5px !important;
            top:20px !important;
            }
            .small-star-demo{
            right: -15px !important;
            top: 45px !important;
            } 
     
    }
    

@media (max-width:1275px){
    .form-bg {
        width: 70%;
        margin-top: 50px;
        align-self: center !important;
    }
    .big-star-demo{
        right: -10px !important;
        top:35px !important;
        }
        .small-star-demo{
        right: -20px !important;
        top: 55px !important;
        } 
 
}

@media (max-width:768px){
    .form-bg {
        width: 90%;
    }
}

@media (max-width:576px){
    .form-bg {
        width: 95%;
    }
    .combined-word-demo{
        font-size: 22px !important;
        margin-top: -80px;
    }
    .discover-text{
        margin-bottom: 0px !important;
    }
    .big-star-demo{
        top:-50px !important;
    }
    .small-star-demo{
        top:-30px !important;
    }
}
