@import '../../../styles/variables.scss';
 
.platform-title-normal {
    font-size: 14px;
    font-weight: 400;
    line-height:15.12px;
    color: #{$color3};
    font-family: #{$sora-light};
}

@media(max-width:1275px){
    .platform-title-normal {
        font-size: 10px;
    }

    .hide-content {
        display: none;
    }
}

@media(max-width:768px){
    .platform-title-normal {
        font-size: 8px;
    }
}