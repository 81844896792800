@import '../../../styles/variables.scss';


.top-blog-img {
    width:50%;
}

.top-blog-cont {
    width: 90%;
    padding-top: 30px;
}

.tob-blog-content {
    width: 45%;
}

@media (max-width:1275px) {
    .top-blog-cont {
        flex-direction: column !important;
        align-items: center !important;
    }

    .tob-blog-content {
        width: 100%;
        margin-top: 10px;
    }

    .top-blog-img {
        width: 100%;
    }
}

@media (min-width:1550px) {
    .top-blog-cont {
        width: 70%;
    }
}

@media(min-width:2500px) {
    .top-blog-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .top-blog-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .top-blog-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .top-blog-cont {
        width: 25%;
    }
}


@media (max-width:860px) {
    .top-blog-cont {
        padding-top: 60px;
    }
}
