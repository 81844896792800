@import '../../../../styles/variables.scss';

.hr-slide-par{
    width: 100%;
}

.hr-slide-child{
    width: 90%;
}

.hr-slide-one{
    width: 70%;
}

.hr-slide-two{
    width: 100%;
}

.hr-slide-ul{
    margin: 0;
    padding: 0;
    width: 100%;
}

.hr-slide-items{
    list-style: none;
    // width: 22%;
}


.icon-right-arrows{
    cursor: pointer;
    rotate: 180deg;
}

.icon-left-arrows{
    cursor: pointer;
}

.icon-con{
    gap:20px;
}

.hr-slide-icons {
    width: 100%;
}

#hrChild {
    min-width: 310px !important;
}

#hrSlideContainer {
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.slide-line {
    background-color:rgba(#D9D9D9, 0.6);
}

@media(min-width:1550px){
    .hr-slide-child{
        width: 70%;
    }

    #hrChild {
        min-width: 335px !important;
    }
}

@media(min-width:1605px) {
    #hrChild {
        min-width: 347px !important;
    }
}

@media(min-width:1654px) {
    #hrChild {
        min-width: 357px !important;
    }
}

@media(min-width:1698px) {
    #hrChild {
        min-width: 367px !important;
    }
}

@media(min-width:1743px) {
    #hrChild {
        min-width: 377px !important;
    }
}

@media(min-width:1784px) {
    #hrChild {
        min-width: 388px !important;
    }
}

@media(min-width:1831px) {
    #hrChild {
        min-width: 398px !important;
    }
}

@media(min-width:1872px) {
    #hrChild {
        min-width: 408px !important;
    }
}

@media(min-width:1919px) {
    #hrChild {
        min-width: 418px !important;
    }
}

@media(min-width:1962px) {
    #hrChild {
        min-width: 428px !important;
    }
}

@media(max-width:1275px) {
    .hr-slide-one{
        width: 90%;
    }
}

@media (max-width:1513px) {
    #hrChild {
        min-width: 301px !important;
    }
}

@media (max-width:1480px) {
    #hrChild {
        min-width: 404px !important;
    }
}


@media (max-width:1436px) {
    #hrChild {
        min-width: 391px !important;
    }
}

@media (max-width:1398px) {
    #hrChild {
        min-width: 379px !important;
    }
}


@media (max-width:1355px) {
    #hrChild {
        min-width: 365.5px !important;
    }
}


@media (max-width:1310px) {
    #hrChild {
        min-width: 351px !important;
    }
}

@media (max-width:1263px) {
    #hrChild {
        min-width: 340px !important;
    }
}


@media (max-width:1223px) {
    #hrChild {
        min-width: 330px !important;
    }
}

@media (max-width:1190px) {
    #hrChild {
        min-width: 320px !important;
    }
}


@media (max-width:1155px) {
    #hrChild {
        min-width: 310px !important;
    }
}

@media (max-width:1125px) {
    #hrChild {
        min-width: 466px !important;
    }
}

@media (max-width:1082px) {
    #hrChild {
        min-width: 456px !important;
    }
}

@media (max-width:1061px) {
    #hrChild {
        min-width: 446px !important;
    }
}

@media (max-width:1040px) {
    #hrChild {
        min-width: 436px !important;
    }
}

@media (max-width:1014px) {
    #hrChild {
        min-width: 426px !important;
    }
}

@media (max-width:993px) {
    #hrChild {
        min-width: 416px !important;
    }
}

@media (max-width:970px) {
    #hrChild {
        min-width: 406px !important;
    }
}

@media (max-width:947px) {
    #hrChild {
        min-width: 396px !important;
    }
}

@media (max-width:926px) {
    #hrChild {
        min-width: 386px !important;
    }
}

@media (max-width:905px) {
    #hrChild {
        min-width: 377px !important;
    }
}

@media (max-width:884px) {
    #hrChild {
        min-width: 368px !important;
    }
}

@media (max-width:861px) {
    #hrChild {
        min-width: 358px !important;
    }
}

@media (max-width:842px) {
    #hrChild {
        min-width: 348px !important;
    }
}

@media (max-width:817px) {
    #hrChild {
        min-width: 338px !important;
    }
}

@media (max-width:798px) {
    #hrChild {
        min-width: 328px !important;
    }
}

@media (max-width:777px) {
    #hrChild {
        min-width: 318px !important;
    }
}

@media (max-width:754px) {
    #hrChild {
        min-width: 508px !important;
    }

    #hrSlideContainer {
        width: 80%;
    }

    .hr-slide-icons {
        width: 85%;
    }
}

@media (max-width:709px) {
    #hrChild {
        min-width: 469px !important;
    }
}

@media (max-width:656px) {
    #hrChild {
        min-width: 431px !important;
    }
}


@media (max-width:601px) {
    #hrChild {
        min-width: 391px !important;
    }
}

@media (max-width:545px) {
    #hrChild {
        min-width: 352px !important;
    }
}


@media (max-width:503px) {
    #hrChild {
        min-width: 412px !important;
    }

    #hrSlideContainer {
        width: 100%;
    }

    .hr-slide-icons {
        width: 100%;
    }
}

@media (max-width:460px) {
    #hrChild {
        min-width: 374px !important;
    }
}

@media (max-width:419px) {
    #hrChild {
        min-width: 336px !important;
    }
}

@media (max-width:376px) {
    #hrChild {
        min-width: 305px !important;
    }
}




@media(min-width:2500px) {
    .hr-slide-child{
        width: 45% !important;
    }
}

@media(min-width:3500px) {
    .hr-slide-child{
        width: 40% !important;
    }
}

@media(min-width:4500px) {
    .hr-slide-child{
        width: 30% !important;
    }
}

@media(min-width:5000px) {
    .hr-slide-child{
        width: 25% !important;
    }
}


