@import '../../../styles/variables.scss';


.our-values-cont {
    width: 90%;
}

.our-values {
    width: 100%;
}

.security-cont {
    width: 100%;
}

.values-btn-cont {
    width: 50%;
    margin-top: 40px;
}

.inner-security-cont {
    width: 100%;
}

.values-btn {
    width:40%;
}

.value-card {
    width: 15%;
    min-height: 320px;
}

@media(max-width:1275px){
    .inner-security-cont {
        flex-wrap: wrap;
    }

    .value-card {
        width: 48%;
        margin-bottom: 20px;
        min-height: 230px;
    }
}


@media (max-width:992px){
    .our-values-cont{
        flex-direction: column !important;
        justify-content: start !important;
    }

    .our-values {
        width: 100%;
    }

    .security-cont {
        margin-top: 30px;
        width: 100%;
    }
}

@media (max-width:768px){
    .security-cont {
        width: 100%;
    }
}

@media (max-width:576px){
    .values-btn-cont {
        flex-direction: column !important;
    }

    .values-btn {
        margin-right: 0 !important;
        margin-bottom: 20px;
        width: 80%;
    }

    .inner-security-cont {
        width: 90%;
    }

    .value-card {
        width: 100%;
    }
}


@media (min-width:1550px){
    .our-values-cont{
        width: 70%;
    }
}


@media(min-width:2500px) {
    .our-values-cont{
        width: 45%;
    }
}

@media(min-width:3500px) {
    .our-values-cont{
        width: 40%;
    }
}

@media(min-width:4500px) {
    .our-values-cont{
        width: 30%;
    }
}

@media(min-width:5000px) {
    .our-values-cont{
        width: 25%;
    }
}