@import '../../../styles/variables.scss';

.search-box {
    background-color: rgba(118, 118, 128, 0.1);
    border-radius: 8px;
    height:30px;
    padding-left: 10px;

}

.search-input {
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    margin-left: 10px;
    font-size: 14px;
    font-family: #{$lexend-deca};
}

.search-icon {
    color: rgba(118, 118, 128, 0.8);
    font-size: 18px !important;
}


.search-input::placeholder {
    color: rgba(118, 118, 128, 0.4);
    font-size: 12px;
    font-weight: 400;
}

.blog-lang{
    display: none !important;
}

@media (max-width:1130px) {
    #blogLinks {
        display: none !important;
    }

    #blogContrast {
        display: none !important;
    }
    .blog-lang{
        display: flex !important;
    }
}
