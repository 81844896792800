@import '../../../styles/variables.scss';
.logo-align {
  vertical-align: middle; /* Align logo vertically to the middle of the text */
  line-height: normal; /* Adjust line-height if needed to ensure proper alignment */
}

.BaseBtn span {
  vertical-align: middle; /* Ensure the span aligns with the middle of the line */
}

.bgnd-profile {
  width: 355px;
  height: 355px;
  background-image: radial-gradient(circle, #feeadb, #feeadb); /* Lighter shades */
  background-size: cover;
  box-shadow: 0 0 40px 25px #feeadb; /* Lighter box-shadow */
  opacity: 0.2;
  z-index: 0;
  position: absolute;
}


.card-partnership {
  display: flex;
  flex-direction: column;  // Stack the content vertically
  justify-content: center;  // Center content horizontally
  align-items: center;      // Center content vertically
  text-align: center;       // Center the text
  height: 100%;             // Ensure the container takes the full height
}


.card-div
{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  align-items: stretch;
}
.custom-shape-divider-bottom-1740645462 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1740645462 svg {
  position: relative;
  display: block;
  width: calc(300% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1740645462 .shape-fill {
  fill: #ffffff;
}
.profile-bg{
    width: 100%;
    padding: 0 0 120px 0;
    margin-bottom: 20px;
    background-color: #fef4ea;
}
/* Text Styles */
.text-grow {
  color: #40c2b9;
  font-size: smaller !important;
}

.highlighted-text {
  color: #f45600;
}

/* Button Styles */
.cta-button {
  background-color: #f45600;
  color: white;
  border: none;
  padding: 10px 20px; /* Adjust padding to make it fit content */
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  display: inline-block;
  width: auto;

  &:hover {
    background-color: darken(#f45600, 10%);
  }
}

/* Responsive Design */
@media (max-width: 767px) {
  .cta-button {
    width: auto; /* Ensures button width is only as wide as its content */
  }
}
.btn-together {
  width: 35%; // Default width for larger screens

  // Media query for screens smaller than 997px
  @media (max-width: 997px) {
    width: 50%; // Adjust width to 50% for small screens
  }
}
@media  (max-width: 991px) {
  
  .topspace{
    margin-top: 40px;
  }
  .text-grow {
    font-size: 18px; /* Slightly smaller text for medium screens */
  }

  .highlighted-text {
    font-size: 24px;
  }

  .cta-button {
    font-size: 14px; /* Smaller button text */
  }
}

/* Larger Screen Styles */
@media (min-width: 992px) {

  .text-grow {
    font-size: 20px;
  }

  .highlighted-text {
    font-size: 30px;
  }

  .cta-button {
    font-size: 18px;
  }
}

/* Ensure the column layout looks good */
.row {
  width: 100%;
}

.col-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjust image sizing */
// .img-fluid {
//   max-width: 100%;
//   height: auto;
// }
.profimg{
  width: 100%;
  height: 440px;
  object-fit: cover;
}


