@import '../../../styles/variables.scss';


.product-menus-cont {
    width: 90%;
    border-radius: 0 0 21px 21px;
    position: fixed;
    z-index: 9;
    top:70px;
    height: 75vh;
}

.menu-shad {
    box-shadow: 0 4px 4px 0 rgba($color: #F45600, $alpha: 0.1);
}

@media(max-height:900px) and (min-width:1550px) {
    .product-menus-cont {
        height: 65vh !important;
    }

    .menu-data-list {
        height: 43vh !important;
    }
}

@media(max-height:850px) and (min-width:1550px) {
    .product-menus-cont {
        height: 68vh !important;
    }

    .menu-data-list {
        height: 45vh !important;
    }
}

@media (min-width:1550px) {
    .product-menus-cont {
        width: 70%;
        height: 60vh;
    }
}

@media(min-width:2500px) {
    .product-menus-cont {
        width: 45%;
        height: 40vh !important;
    }

    .menu-data-list {
        height: 30vh !important;
    }
}

@media(min-width:3500px) {
    .product-menus-cont {
        width: 40%;
        height: 30vh !important;
    }

    .menu-data-list {
        height: 20vh !important;
    }
}

@media(min-width:4500px) {
    .product-menus-cont {
        width: 30%;
    }
    
}

@media(min-width:5000px) {
    .product-menus-cont {
        width: 25%;
        height: 20vh !important;
    }

    .menu-data-list {
        height: 10vh !important;
    }
}

@media (max-width:860px) {
    .product-menus-cont {
        top: 57px;
        height: 62vh;
    }
}