@import '../../../styles/variables.scss';

.blog-avatar {
    width: 40px;
    height:40px;
    border-radius: 20px;
}

#blogDetails {
    width: 90%;
}

.blog-heading{
    margin-top: 30px;
}

@media (min-width:1550px) {
    #blogDetails {
        width: 70%;
    }
}


@media(min-width:2500px) {
    #blogDetails {
        width: 45%;
    }
}

@media(min-width:3500px) {
    #blogDetails {
        width: 40%;
    }
}

@media(min-width:4500px) {
    #blogDetails {
        width: 30%;
    }
}

@media(min-width:5000px) {
    #blogDetails {
        width: 25%;
    }
}

@media(max-width:860px){
    .blog-heading{
        margin-top: 70px !important;
    }
}