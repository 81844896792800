@import '../../../Styles/variables.scss';


.parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60%;
    align-self: center;
    overflow: hidden;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 10%, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
}

.mask {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 10%, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
}

.mr {
  margin-right: 15px;
}



  /* Slider */
.slick-slide {
  margin: 0px 20px;
}


.popup {
  box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.1);
  width:280px;
  padding: 20px 20px 15px 20px;
  z-index: 4;
}

.menu-item-box {
  height:40px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-content {
  flex-wrap: nowrap;
  overflow: hidden;
  height: 50px;
  margin-top: 50px;
  width: 1000px;
}

@media (min-width:1010px){
  .popup{
    display: none !important;
  }
}

@media (max-width:576px){
  .popup{
    width: 280px;
  }
}