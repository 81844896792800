@import '../../../styles/variables.scss';

.payroll-bg{
    margin:  0 0 50px 0;
    padding-bottom: 50px;
    position: relative;
}

.payroll{
    width: 90%;
}

.lexend-deca{
    font-family: #{$lexend-deca};
}

.sora{
    font-family: #{$sora};
}

.payroll-main-1{
    width: 90%;
    margin: 100px 0 0 0;
}

.payroll-main-2{
    width: 68%;
    margin: 0 0 0 50px;
}

.payroll-banner{
    width: 90%;
    z-index: 1;
}

.dotted-oval{
    position: absolute;
    width: 450px;
    height: 250px;
    border-radius: 50%;
    border: 3px dotted rgb(241, 239, 239);
    z-index: -10;
}

.payroll-orange{
    position: absolute;
    width: 100px;
    height: 100px;
    background-image: radial-gradient(circle, #FFCB94, #FFCB94);
    background-size: cover;
    box-shadow: 0 0 60px 45px #FFCB94;
    opacity: 0.2;
    left: 0;
    z-index: -10;
}

.orange-right{
    position: absolute;
    width: 100px;
    height: 100px;
    background-image: radial-gradient(circle, #FFCB94, #FFCB94);
    background-size: cover;
    box-shadow: 0 0 60px 45px #FFCB94;
    opacity: 0.2;
    top: 0;
    right: 50px;
    z-index: -10;
}

.green{
    position: absolute;
    width: 100px;
    height: 100px;
    background-image: radial-gradient(circle, #c0ffc2, #c0ffc2);
    background-size: cover;
    box-shadow: 0 0 60px 45px #c0ffc2;
    opacity: 0.2;
    bottom: 0;
    left: 250px;
    z-index: -10;
}

.green-bottom{
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-image: radial-gradient(circle, #add9ad, #add9ad);
    background-size: cover;
    box-shadow: 0 0 50px 40px #add9ad;
    opacity: 0.3;
    z-index: -10;
}

.one-finance-wrapper{
    width: 50%;
}

.banner-one{
    margin-bottom: 150px;
    width: 100%;
}
.banner-two{
    width: 100%;
}
.banner-three{
    width: 100%;
    margin: 0 30px 30px 30px;
}
.banner-four{
    width: 100%;
}

#LeftPayroll{
    path{
        fill: #72d572 !important;
    }
}
#Payroll-top{
    path{
        fill: #72d572 !important;
    }
}
#Payroll-bottom{
    path{
        fill: #72d572 !important;
    }
}
.payroll-top{
    top: 90px;
    // z-index: -1;
}
.payroll-bottom{
    bottom: 130px;
    // z-index: -1;
}

@media(min-width:1550px){
    .payroll{
        width: 70%;
    }
    .one-finance{
        width: 70% !important;
    }
}

@media(min-width:2500px) {
    .payroll{
        width: 45% !important;
    }
    .one-finance{
        width: 45% !important;
    }
    .one-finance-wrapper{
        width: 100% !important;
    }
}

@media(min-width:3500px) {
    .payroll{
        width: 40% !important;
    }
    .one-finance{
        width: 40% !important;
    }
}

@media(min-width:4500px) {
    .payroll{
        width: 30% !important;
    }
    .one-finance{
        width: 30% !important;
    }
}

@media(min-width:5000px) {
    .payroll{
        width: 25% !important;
    }
    .one-finance{
        width: 25% !important;
    }

}

@media(max-width:1270px){
    .payroll{
        flex-direction: column !important;
        align-items: center !important;
    }
    .payroll-main-1{
        align-items: center !important;
        margin: 50px 0 0 0;
        p{
            text-align: center !important;
        }
    }
    .payroll-main-2{
        margin: 0;
    }
}

@media(max-width:860px){
    .payroll{
        margin: 50px 0 0 0;
    }
    .payroll-banner{
        width: 100%;
    }
    .payroll-main-2{
        width: 100%;
    }
    .green{
        width: 70px;
        height: 70px;
    }
    .orange{
        width: 70px;
        height: 70px;
    }
    .orange-right{
        width: 70px;
        height: 70px;
    }
    .green-bottom{
        width: 70px;
        height: 70px;
    }
    .one-finance-wrapper{
        width: 100%;
    }
    .dotted-oval{
        width: 370px;
        height: 170px;
    }
}

@media(max-width:550px){
    .dotted-oval{
        width: 350px;
        height: 170px;
    }
}

@media(max-width:450px){
    .dotted-oval{
        width: 350px;
        height: 150px;
    }
}

@media(max-width:390px){
    .dotted-oval{
        width: 200px;
        height: 100px;
    }
    .orange-right{
        width: 50px;
        height: 50px;
        top: 70px;
        right: 50px;
    }
    .green{
        width: 50px;
        height: 50px;
        bottom: 150px;
        left: 60px;
    }.payroll-main-2{
        margin-top: -50px;
    }
    .banner-astro{
        position: absolute;
        bottom: 120px;
        left: 50px;
        width: 70px;
    }
}