.cycle-life-cycle {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100vh;
    background-color: #ffffff;
  }
  
  .cycle-circle-container {
    position: relative;
    width: 440px; /* Increased diameter of the circle */
    height: 440px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* Removed overflow: hidden to allow bullet to be visible outside */
  }
  
  .cycle-arrow-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* So that it doesn't block interactions with other elements */
    z-index: 0; /* Ensures the arrow stays behind any other content */
  }
  
  .cycle-arrow {
    width: 100%;
    height: 100%;
    border: 1px solid #f0eded;
    border-radius: 50%;
    position: absolute;
    z-index: 0; /* The arrow stays behind everything */
    transform: rotate(45deg); /* Angle of the arrow */
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 50% 75%, 50% 100%, 0% 100%); /* Cut off the bottom 1/4 of the circle */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  // .cycle-bullet-start {
  //   position: absolute;
  //   top: 85%;  /* Adjusted top position */
  //   left: 15%; /* Adjusted left position */
  //   width: 10px; /* Size of the bullet */
  //   height: 10px; /* Size of the bullet */
  //   background-color: #e3e3e3;
  //   border-radius: 50%; /* Makes it a circle */
  //   transform: translate(-50%, -50%) rotate(45deg); /* Position it at the start of the arrow */
  //   z-index: 0; /* Ensure it appears above the arrow */
  // }
  /* Container for all the bullets and their text */
  .cycle-bullet-container {
    position: relative;
    width: 100%;  /* Adjust width as needed */
    height: 100%;  /* Adjust height as needed */
  }
  
  .cycle-bullet-1, .cycle-bullet-2, .cycle-bullet-3, .cycle-bullet-4, .cycle-bullet-5 {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    transform: translate(-50%, -50%) rotate(45deg); /* Tilt the bullet */
    z-index: 0;
  }
  
  /* Specific positions for each bullet */
  .cycle-bullet-1 {
    top: 70%;
    left: 4%;
  }
  
  .cycle-bullet-2 {
    top: 30%;
    left: 4%;
  }
  
  .cycle-bullet-3 {
    top: 0%;
    left: 50%;
  }
  
  .cycle-bullet-4 {
    top: 30%;
    left: 96%;
  }
  
  .cycle-bullet-5 {
    top: 70%;
    left: 96%;
  }
  
  /* Common styles for headings and paragraphs */
  .bullet-text h3 {
    margin: 0;
    font-size: 14px;
    color: #333;
  }
  
  .bullet-text p {
    margin: 5px 0 0 0;
    font-size: 12px;
    color: #666;
  }
  
  /* Base styles for bullet text */
  .bullet-text {
    position: absolute;
    z-index: 3; /* Ensure text is above the bullet */
    width: 250px; /* Adjust width as needed */
    top: 50%;
    transform: translateY(-50%); /* Vertically center the text */
  }
  
  /* Bullet 1 - Position text to the left of the bullet */
  .bullet-text-1 {
    left: 4%;  /* Position bullet text near bullet 1 */
    transform: translateX(-90%) translateY(150%) rotate(315deg); /* Position text to the left of the bullet */
    text-align: right; /* Align text to the right */
  }
  
  /* Bullet 2 - Position text to the left of the bullet */
  .bullet-text-2 {
    left: 4%;  /* Position bullet text near bullet 2 */
    transform: translateX(-90%) translateY(100%) rotate(315deg); /* Position text to the left of the bullet */
    text-align: right;
  }
  
  /* Bullet 3 - Center the text */
  .bullet-text-3 {
    left: 50%;  /* Center the text horizontally for bullet 3 */
    transform: translateX(-63%) translateY(-110%) rotate(315deg); /* Center text and rotate */
    text-align: center;
  
  }
  
  /* Bullet 4 - Position text to the right of the bullet */
  .bullet-text-4 {
    right: 4%;  /* Position bullet text near bullet 4 */
    transform: translateX(90%) translateY(-250%) rotate(315deg); /* Position text to the left of the bullet */
    text-align: left; /* Align text to the left */
  }
  
  /* Bullet 5 - Position text to the right of the bullet */
  .bullet-text-5 {
    right: 4%;  /* Position bullet text near bullet 5 */
    transform: translateX(90%) translateY(-250%) rotate(315deg); /* Position text to the left of the bullet */
    text-align: left;
  }
  
  
  // .cycle-bullet-end {
  //   position: absolute;
  //   top: 85%;  /* Adjusted top position */
  //   left: 85%; /* Adjusted left position */
  //   width: 15px; /* Size of the bullet */
  //   height: 15px; /* Size of the bullet */
  //   width: 10px; /* Size of the bullet */
  //   height: 10px; /* Size of the bullet */
  //   background-color: #e3e3e3;
  //   border-radius: 50%; /* Makes it a circle */
  //   transform: translate(-50%, -50%) rotate(45deg); /* Position it at the start of the arrow */
  //   z-index: 0; /* Ensure it appears above the arrow */
  // }
  .cycle-text-wrapper {
    position: absolute;
    z-index: 2; /* Make sure the text stays above the arrow */
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333; /* You can adjust the color */
    width: 100%; /* Ensure text spans the entire width of the container */
    top: 50%; /* Vertically center text */
    left: 50%; /* Horizontally center the text */
    transform: translate(-50%, -50%); /* Center text by translating it back */
  }
  
  .cycle-heading p {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  .bullet-text-1 h3 {
    color: #7A3DD9;
  }
  
  .bullet-text-2 h3 {
    color:#FF9D03  /* Example color for bullet 2 (green) */
  }
  
  .bullet-text-3 h3 {
    color: #FF3654;  /* Example color for bullet 3 (blue) */
  }
  
  .bullet-text-4 h3 {
    color:#1D39CF;  /* Example color for bullet 4 (yellow) */
  }
  
  .bullet-text-5 h3 {
    color: #00B59A;  /* Example color for bullet 5 (purple) */
  }
  
  .cycle-icon-lg {
    width: 40px;  /* Set appropriate width for your icons */
    height: 40px; /* Set appropriate height for your icons */
    transform: rotate(315deg); /* Rotate icon by 45 degrees */
  }
  // ------------------------------------------------------------------------------------------------------------------------------------
  .cycle-arrow-wrapper {
    position: relative;
  
    .cycle-bullet-container {
      .cycle-bullet-1,
      .cycle-bullet-2,
      .cycle-bullet-3,
      .cycle-bullet-4,
      .cycle-bullet-5 {
        opacity: 0;
        transition: opacity 1s ease;
        &.show {
          opacity: 1;
        }
        &:nth-child(1) {
          transition-delay: 0.5s;
        }
        &:nth-child(2) {
          transition-delay: 1s;
        }
        &:nth-child(3) {
          transition-delay: 1.5s;
        }
        &:nth-child(4) {
          transition-delay: 2s;
        }
        &:nth-child(5) {
          transition-delay: 2.5s;
        }
      }
    }
  }
  
  // .rotate {
  //   animation: rotateArrow 2s ease-in-out forwards; /* Ensure duration is sufficient */
  // }
  
  // @keyframes rotateArrow {
  //   0% {
  //     transform: rotate(0deg);
  //   }
  //   100% {
  //     transform: rotate(360deg);
  //   }
  // }

  .cycle-arrow {
    animation: rotateArrow 4s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
     box-shadow: 0 0 15px rgba(194, 194, 198, 0.2); /* Glowing effect */  
    }
  
  @keyframes rotateArrow {
    0% {
      transform: rotate(0deg);  /* Initial position */
    }
    100% {
      transform: rotate(360deg);  /* Full rotation */
    }
  }
  
    // ------------------------------------------------------------------------------------------------------------------------------------

  /* Specific positions for the arrow and other elements */
  .cycle-arrow-wrapper {
    opacity: 1;  /* Keep it visible initially */
  }
  
  .cycle-arrow {
    opacity: 1;  /* Make sure arrow is visible */
    transform-origin: center center;
  }
 
  .cycle-card {
    //padding-bottom: 5px;  /* Add space inside the card */
    margin-bottom: 10px;
    border-radius: 8px;  /* Rounded corners */
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Soft shadow */
    border: 2px solid #e3e3e3;  /* Light gray border */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 970px) {
    .bullet-text {
      display: none;  
    }
    .cycle-circle-container {
      position: relative;
      width: 400px; 
      height: 400px;
    }
  }
  /* Show .cycle-card only on screen sizes less than 997px */
  @media (max-width: 997px) {
    .cycle-card {
      display: block; /* Show the card */
      width: 100%; /* Full width of the container */
      box-sizing: border-box;
      color: #999797;
      font-size: 14px;
      background-color: transparent; 
    }
  }
  
  
  /* Card colors */
  .cycle-card-1 h5 {
    color: #7A3DD9; /* Purple */
  }
  
  .cycle-card-2 h5 {
    color: #FF9D03; /* Orange */
  }
  
  .cycle-card-3 h5 {
    color: #FF3654; /* Red */
  }
  
  .cycle-card-4 h5 {
    color: #1D39CF; /* Blue */
  }
  
  .cycle-card-5 h5 {
    color: #00B59A; /* Green */
  }
  