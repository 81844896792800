@import "../../../styles/variables.scss";

.label-box {
    height:45px !important;
    width: 95%;
    background-color: rgba(#F5f5f5, 0.5);
    border: none;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-left: 10px;
}
.center-certificates-activate {
    height: 100vh;
   
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    border-right:1px solid white;
}
.inner-certificates-activate {
    background-color: white;
    border-radius: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; 
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
}

.label-clr {
    color: rgba(#090914, 0.8);
}

.account-cont {
    width: 60%;
}

.close-popup-x {
    top: -15px;
    margin-top: -10px;
    margin-bottom: 10px;
}

.popup-tick {
    min-width: 50px;
    height: 50px;
}
.back-to-home{
    position: absolute;
    // top:35px;
    // right:0px;
    // top: 0 !important;
    // right: 0 !important;
}
.cancel-btn {
    border: 1px solid #{$secondary};
    background-color: #ffffff;
    color: #{$secondary};
    height: 42px;
    width: 45%;
    font-size: 16px;
}

.activate-btn {
    border: none;
    color: #ffffff;
    background-color: #{$secondary};
    height: 42px;
    width: 45%;
    font-size: 16px;
}

.activate-popup {
    border: 1px solid rgba(#F45600, 0.04);
    box-shadow: 0 20px 20px 0 rgba(#F45600, 0.05);
    padding: 36px 40px;
    width: 40%;
    top: 35%;
    left: 30%;
}

.popup-head {
    color: #54595E;
}

.popup-para{
    color: rgba( #54595E, 0.6);
}

@media(min-width:1550px) {
    .account-cont {
        width: 55%;
    }
}

@media (max-width:992px) {
    .account-cont {
        width: 60%;
    }

    .activate-popup {
        width: 45% !important;
        // left: 15%;
        padding: 10px !important;
        left: 25%;
    }

    .cancel-btn {
        width: 40%;
    }
    
    .activate-btn {
        width: 40%;
    }

    .close-popup-x {
        top: -5px;
    }
    .cancel-btn {
      
      
        font-size: 13px !important;
    }
    .activate-btn{
        font-size: 13px !important;
    }
    .popup-head{
        font-size: 15px !important;
    }
}

@media (max-width:576px) {
    .account-cont {
        width: 80%;
    }

    .activate-popup {
        width: 60% !important;
        // left: 5%;
        padding: 10px !important;
        
    }

    .cancel-btn {
        width: 48%;
    }
    
    .activate-btn {
        width: 48%;
    }

    .close-popup-x {
        top: -5px;
        margin-top: 10px;
    }

    .popup-head {
        font-size: 16px !important;
    }

    .popup-para {
        font-size: 12px !important;
    }
}