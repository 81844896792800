@import '../../../styles//variables.scss';

.pink-bg{
    width: 100%;
    margin: 100px 0 0 0;
}

.payroll-self{
    width: 90%;
}

.delivery-quotes{
    width: 60%;
    padding: 10px;
    ul li {
        margin-right: 30px;
    }
}

@media(min-width:1550px){
    .payroll-self{
        width: 70%;
    }
}
@media(min-width:2500px) {
    .payroll-self{
        width: 45% !important;
    }
}

@media(min-width:3500px) {
    .payroll-self{
        width: 40% !important;
    }
}

@media(min-width:4500px) {
    .payroll-self{
        width: 30% !important;
    }
}

@media(min-width:5000px) {
    .payroll-self{
        width: 25% !important;
    }

}


@media(max-width:1150px){
    .payroll-self{
        flex-direction: column !important;
    }
    .delivery-ul{
        flex-direction: column !important;
    }
    .delivery-quotes{
        width: 100%;
    }
    .scooter{
        margin: 0 !important;
    }
}

@media(max-width:390px){
    .scooter{
        height: 250px;
    }
}