@import '../../../styles/variables.scss';

.privacy-policy{
    width: 90%;
    font-family: #{$lexend-deca};
    border-radius: 10px;
background-image: radial-gradient(circle #FF57223A, #FF57223A);
background-color: #ffffff;
box-shadow: 0 0 10px 1px #FF57223A;
margin-top: -150px;
z-index: 1;
padding:20px;
}
.step-into-card-ts{
    width:580px;
    height:350px;
}
.privacy-info{
    font-weight: 600;
}
.product-tandc-heading{
    margin-top: -50px;
}
.second-tc-s{
    width: 50%;
    margin: 20px 0 0 20px;   
}
.product-inner-cont-terms{
    height: 350px
}


@media (min-width:1550px){
    .privacy-policy{
        width:70%;
    }
}

@media(min-width:2500px) {
    .privacy-policy {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .privacy-policy {
        width: 40%;
    }

}

@media(min-width:4500px) {
    .privacy-policy {
        width: 30%;
    }

}

@media(min-width:5000px) {
    .privacy-policy {
        width: 25%;
    }

}
@media(max-width:1257px){

    .second-tc-s{
        width: 90%;
        margin: 0;
    }
}
@media(max-width:1256px){
    .product-tandc-heading{
        margin-top: -50px;
    }
}
@media(max-width:490px){

    .second-tc-s{
        width: 100%;
    }
}
@media(max-width:860px){

    .product-inner-cont-terms{
        margin-top: 50px;
    }
}