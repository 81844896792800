@import '../../../styles/variables.scss';

.vision-cont {
    width:90%;
}

.our-vision-cont {
    text-align: right;
    margin-bottom: 20px;
}

.vision {
    width: 47%;
}

.mission{
    width: 47%;
}

.vertical-line {
    height:650px;
    width: 2.5px;
}

.our-mission-cont {
    margin-top: 20px;
}

.our-vision-img {
    width:90%;
}

.our-mission-img {
    width:90%;
}

.planet1 {
    top:0;
    right:20%;
}

.planet2 {
    top:10%;
    right:30%;
}

.planet3 {
    top:15%;
    right:20%;
}

.planet4 {
    top:22%;
    right:25%;
}

@media (max-width:992px) {
    .our-vision-img {
        width: 95%;
    }

    .our-mission-img {
        width: 95%;
    }
}

@media (max-width:768px){
    .vision-cont {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: start !important;
        width: 100%;
    }

    .vision {
        flex-direction: row !important;
        justify-content: space-between !important;
        width: 100%;
    }

    .mission {
        flex-direction: row !important;
        justify-content: space-between !important;
        width: 100%;
    }

    .our-vision-cont {
        width: 55%;
    }

    .our-mission-cont {
        width: 55%;
    }

    .stick {
        display: none !important;
    }

    .our-vision-img {
        width: 40%;
    }

    .our-mission-img {
        width: 40%;
    }

    .planet1 {
        top:20%;
        right:60%;
    }
    
    .planet2 {
        top:40%;
        right:68%;
    }
    
    .planet3 {
        top:40%;
        right:60%;
    }
    
    .planet4 {
        top:48%;
        right:65%;
    }

}


@media (max-width:576px){
    .vision {
        flex-direction: column !important;
        justify-content: center !important;
        width: 100%;
    }

    .mission {
        flex-direction: column !important;
        justify-content: center !important;
        width: 100%;
    }

    .our-vision-cont {
        width: 100%;
    }

    .our-mission-cont {
        width: 100%;
        order: 0;
    }

    .our-vision-img {
        width: 90%;
    }

    .our-mission-img {
        width: 90%;
        order: 1;
        margin-top: 20px;
    }


    .planet1 {
        top:70%;
        right:10%;
    }
    
    .planet2 {
        top:80%;
        right:25%;
    }
    
    .planet3 {
        top:80%;
        right:10%;
    }
    
    .planet4 {
        top:85%;
        right:15%;
    }
}

