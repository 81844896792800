
.slick-slide {
    width: fit-content !important;
}

.slide-size {
    width: 75%;
    flex-wrap: nowrap;
    overflow: hidden;
    min-height:25px;
    max-height: 25px;
}

@media (max-width:768px){
    .slide-size{
        width: 90%;
    }
}

@media (max-width:576px){
    .slide-size{
        width: 90% !important;
    }
}

@media (min-width:1550px){
    .slide-size{
        width: 55% !important;
    }
}


@media(min-width:2500px) {
    .slide-size{
        width: 40% !important;
    }
}

@media(min-width:3500px) {
    .slide-size{
        width: 30% !important;
    }
}

@media(min-width:4500px) {
    .slide-size{
        width: 25% !important;
    }
}

@media(min-width:5000px) {
    .slide-size{
        width: 20% !important;
    }
}