@import '../../../styles/variables.scss';


.leadership-cont {
    width: 90%;
    // background-color: #EDF6FF;
}

.leader-img {
    width: 20%;
    z-index: 1;
    cursor: pointer;
}

@media (max-width:576px){
    .leaders {
        justify-content: center !important;
        flex-wrap: wrap;
    }

    .leader-img {
        width: 40%;
        margin: 20px;
    }
}


@media (max-width:450px){
    .leader-img {
        width: 35%;
    }
}




@media (min-width:1550px){
    .leadership-cont{
        width: 70%;
    }
}


@media(min-width:2500px) {
    .leadership-cont{
        width: 45%;
    }
}

@media(min-width:3500px) {
    .leadership-cont{
        width: 40%;
    }
}

@media(min-width:4500px) {
    .leadership-cont{
        width: 30%;
    }
}

@media(min-width:5000px) {
    .leadership-cont{
        width: 25%;
    }
}