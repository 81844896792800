@import '../../../../styles/variables.scss';


.bg-light-grey {
    background-color: #e9f7f7;

    > *:first-child {
        fill: none;
    }

    > *:last-child {
        stroke: #12A8A89A;
    }

}

#hr_a{
    path{
        fill: #12A8A8 !important;
    }
}


.attend-feature-img{
    width: 44%;
    border-radius: 10px;
    background-image: radial-gradient(circle #12A8A85A, #12A8A85A);
    box-shadow: 0 0 10px 1px #12A8A85A;
    margin: 0 20px 0 8px;
}

@media(max-width:1275px){
    .attend-feature-img {
        width: 70%;
    }
}

@media(max-width:576px) {
    .attend-feature-img {
        width: 90%;
    }
}

@media (max-width:390px){
    .attend-feature-img{
        width: 96%;
    }
}