@import '../../../styles/variables.scss';

.demo {
    text-decoration: none;
    color: #{$secondary};
}

.book-demo-btn{
  font-size: 14px;
  background: #{$white};
  color:#{secondary};
  // border: 0.8px solid #{$secondary};
  position: relative;
  z-index: 1;
  overflow: hidden;
}


.book-demo-btn.grow_skew_backward::after {
    content: "";
    background: #{$white};
    position: absolute;
    z-index: -1;
    display: block;
    left: -14%;
    right: 0%;
    height: 42px;
    width: 130%;
    transition: all .3s ease-in-out;
    top: 0;
    bottom: 0;
    transform: skewX(45deg) scale(0, 1);
  }
.book-demo-btn.grow_skew_backward:hover::after {
    transform: skewX(45deg) scale(1, 1);
    background: #{$secondary};
    transition:transform .3s ease-in-out;
}

.btn-4 {
    overflow: hidden;
    position: relative;
    
    span {
      z-index: 20;
    }
    
    &:after {
        background: var(--wz-white) !important;
        content: "";
        height: 155px;
        left: -75px;
        opacity: .5;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: 0;
    }
  }
  
  .btn-4:hover {
    
    &:after {
        left: 120%;
        transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }


  .btn-4-alternate {
    overflow: hidden;
    position: relative;
    
    span {
      z-index: 20;
    }
    
    &:after {
        background: rgb(244, 86, 0, 0.7);
        content: "";
        height: 155px;
        left: -75px;
        opacity: .5;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: 0;
    }
  }
  
  .btn-4-alternate:hover {
    
    &:after {
        left: 120%;
        transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }



  .btn-3 {
    overflow: hidden;
    position: relative;
    
    span {
      z-index: 20;
    }
    
    &:after {
        background: #{$secondary} !important;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: 0;
    }
  }
  
  .btn-3:hover {
    
    &:after {
        left: 120%;
        transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }



  .btn-5 {
    display: inline-block;
    color: #fff;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #{$secondary};
      border-radius: 10rem;
      z-index: -2;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color:#1A2D48;
      transition: all .3s;
      z-index: -1;
    }
    &:hover {
      color: #fff;
      &:before {
        width: 100%;
      }
    }
  }

  .btn-6:hover{
    scale:1.1;
    box-shadow: 0 0 2px 3px rgb(0, 0, 0, 0.1);
  }

  .mob-width {
    width:30%;
  }

  .logo-up{
    margin-bottom: 2px !important;
  }

@media(min-width:1550px){
  .mob-width{
    width: 35%;
  }
}

@media (max-width:1275px) {
  .btn-cont {
    justify-content: center !important;
  }

  .credit-cont {
    justify-content: center !important;
    margin-left: 0px !important;
  }
}


@media (max-width:576px) {
  .btn-cont {
    flex-direction: column !important;
    justify-content: center !important;
  }

  .credit-cont {
    flex-direction: column !important;
    justify-content: center !important;
    margin-left: 0px !important;
  }

  .mob-width {
    width: 80%;
    margin-right: 0 !important;
    margin-top: 20px;
  }

  .best {
    margin-left: 0 !important;
  }
}