@import '../../../styles//variables.scss';


.box-size {
    background-color: #{$color7};
    white-space: nowrap;
}

.slide-size1 {
    flex-wrap: nowrap;
    overflow: hidden;
    height:50px;
}

@media (max-width:768px){
    .slide-size1 {
        width: 100% !important;
    }
    
    .box-size {
     padding-left: 5px !important;
     padding-right: 5px !important;
    }
}
