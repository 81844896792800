@import '../../styles/variables.scss';
.seo-main{
    width: 72%;
    font-family: #{$lexend-deca};
    border-radius: 10px;
background-image: radial-gradient(circle #FF57223A, #FF57223A);
background-color: #ffffff;
box-shadow: 0 0 10px 1px #FF57223A;
margin-top: -80px;
z-index: 1;
padding:20px;
}
.large-hr-solutions{
    display: block !important;
}
.small-hr-solutions{
    display: none !important;
}
@media(max-width:1550px){
    .seo-main{
     width: 90%;
    }
}
@media(max-width:850px){
    .large-hr-solutions{
        display: none !important;
    }
    .small-hr-solutions{
        display: block !important;
    }
    .center-head-seo{
        text-align: center;
    }
}