@import '../../../../styles/variables.scss';

#hr_g{
    path{
        fill: #FF5722 !important;
    }
}

.bg-light-orange {
    background-color: #faeee2;

    > *:first-child {
        fill: none;
    }

    > *:last-child {
        stroke: #FF5722;
    }

}

.geo-feature-img{
    width: 44%;
    border-radius: 20px;
    background-image: radial-gradient(circle #FF57223A, #FF57223A);
    box-shadow: 0 0 10px 1px #FF57223A;
    margin: 0 20px 0 10px;
}

@media(max-width:1275px){
    .geo-feature-img {
        width: 70%;
    }
}

@media(max-width:576px) {
    .geo-feature-img {
        width: 90%;
    }
}

@media (max-width:390px){
    .geo-feature-img{
        width: 96%;
    }
}