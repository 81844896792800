@import '../../../styles/variables.scss';


.about-cont {
    width:90%;
}

.meet {
    width: 90%;
}

.custom-shape-divider-top-1713956975 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1713956975 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 70px;
}

.custom-shape-divider-top-1713956975 .shape-fill {
    fill: #FFFFFF;
}


.custom-shape-divider-bottom-1713957171 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1713957171 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 70px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1713957171 .shape-fill {
    fill: #FFFFFF;
}

.center-bar {
    background-color: #DEE5F3;
    width: 100%;
    height: 90px;
    z-index: 0;
}

.team-members {
    width: 90%;
    flex-wrap: wrap;
}

.emp-cont {
    min-width: 200px !important;
    height: 320px;
}

.emp-img {
    margin: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 0%;
    background-position-y: 0%;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    overflow: hidden;
}


.rot:hover {
    rotate: 45deg;
}

.rot2:hover {
    rotate: 225deg !important;
}

#empContainer-0::before {
    content: "";
    position: absolute;
    background-color: #DEE5F3;
    width: 100%;
    height: 90px;
    z-index: 0;
    top: 38%;
}

.arrow-cont {
    width: 90%;
}

.emp-text {
    font-size: 12px;
}

.emp-detail-height {
    height: 75px;
}

.linkedin-btn {
    border: none;
    background-color: transparent;
    color:#{$secondary};
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

.linkedin-btn:hover {
    background-color: #{$secondary};
    color: #FFFFFF;
    border-radius: 10px;
    width: 40px;
    height: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition:all 0.5s ease-in-out;
    cursor: pointer;
}

.exp-btn {
    font-size: 10px;
    border-radius: 15px;
    height:20px;
    width: 40px;
    margin-top: -30px;
}

@media (min-width:1550px) {
    .about-cont {
        width: 70%;
    }

    .meet {
        width: 70%;
    }

    .team-members{
        width: 70%;
    }

    .arrow-cont {
        width: 70%;
    }
}


@media(min-width:2500px) {
    .about-cont {
        width: 45%;
    }

    .meet {
        width: 45%;
    }

    .team-members{
        width: 45%;
    }

    .arrow-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .about-cont {
        width: 40%;
    }

    .meet {
        width: 40%;
    }

    .team-members{
        width: 40%;
    }

    .arrow-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .about-cont {
        width: 30%;
    }

    .meet {
        width: 30%;
    }

    .team-members{
        width: 30%;
    }

    .arrow-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .about-cont {
        width: 25%;
    }

    .meet {
        width: 25%;
    }

    .team-members{
        width: 25%;
    }

    .arrow-cont {
        width: 25%;
    }
}


// @media (max-width:1450px) {
//     .emp-cont {
//         min-width: 190px !important;
//     }

//     .emp-img {
//         width: 135px !important;
//     }
// }

// @media (max-width:1378px) {
//     .emp-cont {
//         min-width: 180px !important;
//     }

//     .emp-img {
//         width: 130px !important;
//     }
// }


// @media (max-width:1312px) {
//     .emp-cont {
//         min-width: 170px !important;
//     }

//     .emp-img {
//         width: 130px !important;
//     }
// }

// @media (max-width:1250px) {
//     .emp-cont {
//         min-width: 193px !important;
//     }

//     .emp-img {
//         width: 135px !important;
//     }
// }

// @media (max-width:1165px) {
//     .emp-cont {
//         min-width: 180px !important;
//     }

//     .emp-img {
//         width: 130px !important;
//     }
// }

// @media (max-width:1100px) {
//     .emp-cont {
//         min-width: 220px !important;
//     }

//     .emp-img {
//         width: 140px !important;
//     }

//     .team-members {
//         width: 90%;
//     }
// }

// @media (max-width:1000px) {
//     .emp-cont {
//         min-width: 200px !important;
//     }

//     .emp-img {
//         width: 140px !important;
//     }
// }

// @media (max-width:923px) {
//     .emp-cont {
//         min-width: 185px !important;
//     }

//     .emp-img {
//         width: 140px !important;
//     }
// }


// @media (max-width:865px) {
//     .emp-cont {
//         min-width: 172px !important;
//     }

//     .emp-img {
//         width: 140px !important;
//     }
// }

// @media (max-width:816px) {
//     .emp-cont {
//         min-width: 172px !important;
//     }

//     .emp-img {
//         width: 140px !important;
//     }

//     .team-members {
//         width: 95%;
//     }
// }

// @media (max-width:768px) {
//     .emp-cont {
//         min-width: 210px !important;
//     }

//     .emp-img {
//         width: 140px !important;
//     }
// }


// @media (max-width:678px) {
//     .emp-cont {
//         min-width: 185px !important;
//     }

//     .emp-img {
//         width: 140px !important;
//     }
// }


// @media (max-width:618px) {
//     .emp-cont {
//         min-width: 170px !important;
//     }

//     .emp-img {
//         width: 140px !important;
//     }
// }


@media (max-width:576px) {

    .emp-img {
        width: 115px !important;
        height: 115px;
    }

    .emp-text {
        font-size: 8px;
    }

    .emp-detail-height {
        height: 55px;
    }

    .exp-btn {
        font-size: 8px;
        height: 15px;
        margin-top: -21px;
    }
}


// @media (max-width:550px) {
//     .emp-cont {
//         min-width: 150px !important;
//     }

//     .emp-img {
//         width: 130px !important;
//     }
// }

// @media (max-width:505px) {
//     .emp-cont {
//         min-width: 138px !important;
//     }

//     .emp-img {
//         width: 130px !important;
//     }
// }


// @media (max-width:475px) {
//     .emp-cont {
//         min-width: 128px !important;
//         height: 300px;
//     }

//     .emp-img {
//         width: 120px !important;
//     }
// }


// @media (max-width:445px) {
//     .emp-cont {
//         min-width: 118px !important;
//         height: 280px;
//     }

//     .emp-img {
//         width: 110px !important;
//     }
// }

// @media (max-width:410px) {
//     .emp-cont {
//         min-width: 108px !important;
//         height: 260px;
//     }

//     .emp-img {
//         width: 100px !important;
//     }
// }

// @media (max-width:380px) {
//     .emp-cont {
//         min-width: 100px !important;
//         height: 240px;
//     }

//     .emp-img {
//         width: 90px !important;
//     }
// }


// @media (max-width:355px) {
//     .emp-cont {
//         min-width: 95px !important;
//         height: 240px;
//     }

//     .emp-img {
//         width: 90px !important;
//     }
// }




