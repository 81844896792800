@import './styles/variables.scss'; 

body {
  margin: 0;
  font-family: #{$sora};
  color: #1F3549;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: #{$lexend-deca};
}


*{
  box-sizing: border-box;
}

p {
  margin-top: 12px;
  margin-bottom: 12px;
}

#root {
  display: flex; 
  flex-direction: column; 
  overflow-x: hidden;
  overflow-y:auto !important;
  height: 100vh;
}

#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  height: 40px;
  width: 40px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #{$secondary};
  border: none;
}

.og-image {
  max-height: 150px;
  max-width: 150px;
  object-fit: cover;
}