@import '../../../../styles/variables.scss';

#yellowLine{
    rect{
        fill: #b1b115 !important;
    }
}

.leave-merits{
    width: 100%;
    background-image: linear-gradient(to right, #cdcd6b2A, #cdcd6b0A);
}
