@import '../../../../styles/variables.scss';

#Leftw-a{
    path {
        fill: rgba(#12A8A8, 0.4) !important;
    }
}
#attend-top{
    path {
        fill: rgba(#12A8A8, 0.4) !important;
    }
}
#attend-bottom{
    path {
        fill: rgba(#12A8A8, 0.4) !important;
    }
}
.attend-top{
    top: -5px;
    // z-index: -1;
}
.attend-bottom{
    bottom: 35px;
    // z-index: -1;
}

.attend-img{
    width: 80%;
    z-index: 1;
}

.bgnd-attendance {
    width: 355px;
    height: 280px;
    background-image: radial-gradient(circle, #12A8A83A, #12A8A83A);
    background-size: cover;
    box-shadow: 0 0 50px 40px #12A8A83A;
    opacity: 0.5;
}

@media(max-width:780px){
   
    .bgnd-attendance {
        width: 355px;
        height: 200px;
    }
}

@media(max-width:580px){
   
    .bgnd-attendance {
        width: 280px;
        height: 120px;
    }
}

@media(max-width:490px){
   
    .bgnd-attendance {
        width: 260px;
        height: 100px;
    }
}

@media(max-width:390px){
   
    .bgnd-attendance {
        width: 230px;
        height: 120px;
    }
    .attend-top{
        top: -67px;
        z-index: -1;
    }
    .attend-bottom{
        bottom: -30px;
        z-index: -1;
    }
}