@import '../../../styles/variables.scss';


.line-btm {
    border: 1px solid #D0D0D0;
    width: 80%;
}

.decoration {
    text-decoration: none;
}

.recent-blog-cont {
    width: 90%;
}


@media (max-width:1275px) {
    .blog-cont {
        flex-direction: column !important;
    }

    .line-btm {
        width: 60%;
    }
}

@media(max-width:768px) {
    .line-btm {
       display: none;
    }
}

@media (min-width:1550px) {
    .recent-blog-cont {
        width: 70%;
    }
}


@media(min-width:2500px) {
    .recent-blog-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .recent-blog-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .recent-blog-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .recent-blog-cont {
        width: 25%;
    }
}