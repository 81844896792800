@import '../../../styles/variables.scss';

.op {
    opacity: 0.6;
}

.ball {
    height:8px;
    width:8px;
    border-radius: 10px;
}

.line {
    border: 1.5px solid;
    opacity: 0.9;
}

.clock-in-btn {
    border: none;
    width: 90px;
    cursor: default !important;
}

.clock-in {
    height:130px;
}

@media (max-width:576px) {
    .clock-in {
        min-width: 320px !important;
        height:128px;
    }
}