@import '../../../styles//variables.scss';

.about-us-gif {
    position: relative;
    overflow: hidden;
    padding: 250px 0;
}

.about-us-gif::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-image:url('../../../assets/gifs/about-us.gif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.4;
}

.curve-top {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    height: 100px;
    background-color: #f0f0f0;
    border-radius: 50%;
    z-index: 1;
}

.curve-bottom {
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    height: 100px;
    background-color: #f0f0f0;
    border-radius: 50%;
    z-index: 1;
}

#about-star path {
    fill: #{$primary} !important;
}

.custom-shape-divider-bottom-1714115409 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1714115409 svg {
    position: relative;
    display: block;
    width: calc(141% + 1.3px);
    height: 64px;
}

.custom-shape-divider-bottom-1714115409 .shape-fill {
    fill: #FFFFFF;
}

.star1 {
    left: 19% !important;
    top:18%;
}

.star2 {
    left: 21% !important;
    top:23%;
}

.star3 {
    right: 24% !important;
}

.star4 {
    right: 25% !important;
}

.about-text {
    min-height: 38px;
    overflow-y: hidden !important;
    margin-top: -7px;
}

#bgVideo {
    opacity: 0.5;
    top: -100px;
}

@media (max-width:1275px) {
    .star1 {
        top:0%;
        left: 4% !important;
    }

    .star2 {
        top:8%;
        left: 10% !important;
    }

    .star3 {
        right: 2% !important;
    }
    
    .star4 {
        right: 4% !important;
    }

    .about-us-gif {
        justify-content: center !important;
        padding: 200px 10px;
    }

    .about-text{
        margin-top: -8px;
    }
}

@media (max-width:860px) {
    #bgVideo {
        top: -50px;
    }

    .about-us-gif {
        justify-content: center !important;
        padding: 200px 10px;
    }
}

@media (max-width:768px) {
    #bgVideo {
        top: 0px;
    }

    .about-us-gif {
        justify-content: center !important;
        padding: 200px 10px;
    }
}

@media (max-width:576px) {
    .star1 {
        top:0%;
        left: 4% !important;
    }

    .star2 {
        top:8%;
        left: 10% !important;
    }

    .star3 {
        right: 2% !important;
    }
    
    .star4 {
        right: 4% !important;
    }

    .about-us-gif {
        justify-content: center !important;
        padding: 150px 10px;
    }

    .about-text{
        margin-top: -8px;
    }

    #bgVideo {
        top: 0px;
    }
}

@media (max-width:500px){
    .about-text {
        height: 30px;
    }

    .about-us-gif {
        margin-bottom: -30px;
        padding: 120px 10px;
        justify-content: center !important;
    }

    #bgVideo {
        top: 60px;
    }

    .mob-text {
        margin-top: 60px;
    }
}

