@import '../../../Styles/variables.scss';


.number-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: 65%;
}

.book-cont {
    width: 50%;
}

.avatar-cont {
    width: 35%;
    img {
        width: 65px;
        height: 70px;
        border-radius: 50px;
        border: 1px solid #ffffff;
    }
}

.demo-cont {
    width: 90%;
}

.demo-logo-cont {
    width:90%;
}
.signup-nav{
    position: absolute;
    top:30px;
    right:30px;
}
.low-opacity {
    opacity: 0.1 !important;
}
.carousel-book-component{
    margin-top: 90px;
}

@media (max-width:1275px){
    .demo-cont {
        flex-direction: column !important;
    }

    .book-cont {
        width: 100%;
    }
}
@media (max-width:1550px){
    .carousel-book-component{
        margin-top: 70px;
    }
}


@media (max-width:768px){
    .avatar-cont {
        width: 50%;
    }
}

@media (max-width:576px){
    .avatar-cont {
        width: 65%;
    }

    .number-cont {
        width: 90%;
    }
}

@media (min-width:1550px){
    .demo-cont {
        width: 70%;
    }

    .demo-logo-cont {
        width: 70%;
    }

    .demo-height {
        height:100vh;
    }
}


@media(min-width:2500px) {
    .demo-cont {
        width: 45%;
    }

    .demo-logo-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .demo-cont {
        width: 40%;
    }

    .demo-logo-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .demo-cont {
        width: 30%;
    }

    .demo-logo-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .demo-cont {
        width: 25%;
    }

    .demo-logo-cont {
        width: 25%;
    }
}