@import "../../../styles/variables.scss";


.errors {
    margin-left: 10%;
}

.sign-up-first-cont1 {
    width: 50%;
    min-height: 90vh;
}

.sign-up-second-cont1 {
    width: 50%;
    min-height: 90vh;
}

.signup-wz-logo1 {
    padding-left: 5%;
    background-image: linear-gradient(to right, #FFFFFF 50%, #FEF4EA 50%);
}


@media(min-width:1550px) {
    .sign-up-first-cont1 {
        justify-content: center !important;
    }
    
    .sign-up-second-cont1 {
        justify-content: center !important;
    }
}

@media(max-width:992px) {

    .sign-up-first-cont1 {
        width: 100%;
    }

    .sign-up-second-cont1 {
        width: 100%;
    }

    .signup-wz-logo1 {
        background-image: linear-gradient(to right, #FFFFFF 50%, #FFFFFF 50%);
    }
}


@media(max-width:768px) {

    .sign-up-first-cont1 {
        display: none !important;
    }

    .signup-wz-logo1 {
        background-image: linear-gradient(to right, #FEF4EA 50%, #FEF4EA 50%);
    }
}