@import '../../../styles/variables.scss';

.mentor-cont {
    width: 90%;
}

.mentor-img {
    width: 45%;
    z-index: 1;
}

.kural-cont {
    width: 95%;
    margin-top: 30px;
}

.mob-mentor {
    width: 100%;
    display: none !important;
}

.back {
    background-color: #FFFBF2;
    height: 150px;
    width: 190px;
    z-index:0;
    border-top-left-radius: 90px;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 100px;
    rotate: 20deg;
    top:30px;
}

.kural {
    font-size: 14px;
}

.kural-section {
    width: 55%;
}

@media (max-width:992px){
    .mob-mentor {
        display: flex !important;
        margin-top: 30px;
    }

    .kural-cont {
        display: none !important;
    }

    .mentor-img {
        width: 40%;
    }
}

@media (max-width:670px) {
    .mob-mentor {
        flex-direction: column !important;
    }

    .kural-section {
        margin-top: 70px;
        width: 100%;
        align-items: center !important;
    }
}

@media (max-width:576px) {
    .mentor-img {
        width: 55%;
    }
}

@media (max-width:450px) {
    .mentor-img {
        width: 90%;
    }

    .kural {
        font-size: 11px !important;
    }
}

@media (min-width:1550px){
    .mentor-cont {
        width: 70%;
    }
}