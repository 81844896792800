@import "../../styles/variables.scss";

.login-top{
    background-color: #FEF4EA;
    width: 100%;
    height: 100vh;
    font-family: #{$lexend-deca};
    font-weight: 400;
}

.login-form{
    background-color: #fff;
    border-radius: 20px;
    padding: 30px;
    height: auto;
    z-index: 1;
    width: 60%;
}

.login-button{
    margin: 5px 0 10px 0;
    height: 40px;
    border-radius: 10px;
    border: none;
    color: #fff;
    background-color: #F456003A;
    outline: none;
}
.center-login-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.not-success-login-download-container{
    bottom:7px !important ;
}
.link-span{
    padding: 10px;
    width: 100%;
    border-radius: 14px;
    text-decoration: none;
    color: #767676;
    background-color: #F8FAFC;
    outline: none;
}

.name{
    color: #090914;
}

.msg-height-login{
    min-height: 15px !important;
}

.support{
    margin: 10px 0 10px 0;
    color: #71717A;
   
}

.continue{
    color: #727276;
}

.change{
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

.line-log{
    opacity: 0.2;
}

.w-logo{
    padding: 0 5px 5px 0;
    outline: none;
}
.big-star-login{
    position: absolute;
    right:33% !important;
    top:35% !important;

}
.big-star-password{
    position: absolute;
    right:30% !important;
    top:33% !important;

}
.small-star-login{
    position: absolute;
    right:31% !important;
    top:38% !important;

}
.small-star-password{
    position: absolute;
    right:28% !important;
    top:36% !important;

}
.big-star-reset{
    position:absolute;
    right: 30%;
    top:32%;
}
.small-star-reset{
    position:absolute;
    right: 28%;
    top:35%;
}
.big-star-domain{
    position:absolute;
    right: 28%;
    top:30%;
}
.small-star-domain{
    position:absolute;
    right: 26%;
    top:33%;
}
.domain-list{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    list-style: none;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 14px;
    text-decoration: none;
    color: #767676;
    background-color: #F8FAFC;
}

.radio{
    accent-color: #F45600;
}

@media (min-width:1550px){
    .login-form{
        width: 60%;
    }
  
}


@media(min-width:2500px) {
    .login-form{
        width: 15%;
    }
  }

  @media(min-width:3500px) {
    .login-form{
        width: 15%;
    }
  }

  @media(min-width:4500px) {
    .login-form{
        width: 12%;
    }
  }

  @media(min-width:5000px) {
    .login-form{
        width: 10%;
    }
  }


@media (max-width:1550px){
    .big-star-reset{
        position:absolute;
        right: 27%;
        top:32%;
    }
    .small-star-reset{
        position:absolute;
        right: 26%;
        top:35%;
    }
    .big-star-domain{
       
        right: 24%;
        top:30%;
    }
    .small-star-domain{
     
        right: 22%;
        top:33%;
    }
    .big-star-login{
        right:30% !important;
        top:35% !important;
    
    }
    .small-star-login{
        right:28% !important;
        top:38% !important;
    
    }
    .big-star-password{
        // position: absolute;
        right:27% !important;
        top:33% !important;
    
    }
    .small-star-password{
        // position: absolute;
        right:26% !important;
        top:36% !important;
    
    }
}
@media (max-width:1375px){
    .big-star-reset{
    
        right: 24%;
        top:32%;
    }
    .small-star-reset{
    
        right: 21%;
        top:35%;
    }
    .big-star-domain{
      
        right: 22%;
        top:30%;
    }
    .small-star-domain{
    
        right: 20%;
        top:33%;
    }
}

@media (max-width:1275px){
    .login-form{
        width: 75%;
    }
    .big-star-login{
        right:26% !important;
        top:35% !important;
    }
    .small-star-login{
        right:24% !important;
        top:38% !important;
    }
    .big-star-password{
        right:32% !important;
        top:30% !important;
    }
    .small-star-password{
        position: absolute;
        right:30% !important;
        top:33% !important;
    }
    .big-star-reset{
        right: 20%;
        top:32%;
    }
   .small-star-reset{
               
        right:18%;
        top:35%;
            }

            .big-star-domain{
      
                right: 18%;
                top:30%;
            }
            .small-star-domain{
            
                right: 16%;
                top:33%;
            }
            .login-font-domain{
                font-size: 20px !important;
            }
}

@media (max-width:990px){
    // .login-form{
    //     width: 46%;
    // }
    .login-up-second-cont{
        height: 100%;
        background-color: #ffffff;
    }
    .login-font-domain{
        font-size: 24px !important;
    }
    .big-star-password{
        right:32% !important;
        top:32% !important;
    }
    .small-star-password{
        // position: absolute;
        right:30% !important;
        top:35% !important;
    }
    // .not-success-login-download-container{
    //     bottom: 70px !important ;
    //     left:0 !important;
    //     right: 0 !important;
    // }
}

@media (max-width:725px){
    .login-form{
        width: 60%;
    }
    .login-top{
        padding: 0 0 120px 0;
    }
}

@media (max-width:586px){
    .login-form{
        width: 80%;
    }
    .big-star-login{
    right:30% !important;
    top:32% !important;

    }
    .small-star-login{
        right:28% !important;
        top:35% !important;
    
        }
        .big-star-reset{
              
            right: 17%;
            top:32%;
        }
        .small-star-reset{
           
            right:15%;
            top:35%;
        }
        .big-star-domain{
      
            right: 16%;
            top:30%;
        }
        .small-star-domain{
        
            right: 14%;
            top:33%;
        }
        .login-font-domain{
            font-size: 20px !important;
        }
        .big-star-password{
            right:24% !important;
            top:32% !important;
        }
        .small-star-password{
            // position: absolute;
            right:22% !important;
            top:35% !important;
        }
}

@media (max-width:450px){
    .login-form{
        width: 85%;
    }
   
    .login-top{
        padding: 0 0 50px 0;
    }
    .big-star-login{
        right:20% !important;
        top:22% !important;
    
        }
        .small-star-login{
            right:16% !important;
            top:26% !important;
        
            }
            .big-star-password{
                right:18% !important;
                top:23% !important;
            
            }
            .small-star-password{
                position: absolute;
                right:15% !important;
                top:27% !important;
            
            }
            .big-star-reset{
              
                right: 10%;
                top:30%;
            }
            .small-star-reset{
               
                right:8%;
                top:33%;
            }
            .big-star-domain{
      
                right: 12%;
                top:18%;
            }
            .small-star-domain{
            
                right: 8%;
                top:22%;
            }
            .radio-field-url{
                font-size: 13px !important;
            }
            .center-login-container{
            
                margin-bottom: 30px;
    
                width: 100%;
            }
            // .not-success-login-download-container{
            //     bottom: 160px !important ;
            // }
}
