@import '../../../styles/variables.scss';

#categoryContainer {
    width: 90%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.category-box1 {
    min-width:227px !important;
    border: 1px solid #E8EAED;
    height: 40px;
    padding: 0 20px;
    margin-right: 40px;
    cursor: pointer;
}


@media (min-width:1550px) {
    .category-box1 {
        min-width: 295px !important;
    }
}

@media (min-width:1593px) {
    .category-box1 {
        min-width: 308px !important;
    }
}

@media (min-width:1656px) {
    .category-box1 {
        min-width: 320px !important;
    }
}

@media (min-width:1709px) {
    .category-box1 {
        min-width: 331px !important;
    }
}


@media (min-width:1765px) {
    .category-box1 {
        min-width: 342px !important;
    }
}

@media (min-width:1818px) {
    .category-box1 {
        min-width: 354px !important;
    }
}

@media (min-width:1869px) {
    .category-box1 {
        min-width: 262px !important;
    }
}

@media (min-width:1905px) {
    .category-box1 {
        min-width: 270px !important;
    }
}

@media (min-width:1960px) {
    .category-box1 {
        min-width: 278px !important;
    }
}


@media (max-width:1550px) {
    .category-box1 {
        min-width: 274px !important;
    }
}


@media (max-width:1506px) {
    .category-box1 {
        min-width: 265px !important;
    }
}


@media (max-width:1463px) {
    .category-box1 {
        min-width: 256px !important;
    }
}

@media (max-width:1416px) {
    .category-box1 {
        min-width: 247px !important;
    }
}

@media (max-width:1371px) {
    .category-box1 {
        min-width: 238px !important;
    }
}

@media (max-width:1327px) {
    .category-box1 {
        min-width: 229px !important;
    }
}


@media (max-width:1280px) {
    .category-box1 {
        min-width: 220px !important;
    }
}

@media (max-width:1238px) {
    .category-box1 {
        min-width: 293px !important;
    }
}

@media (max-width:1189px) {
    .category-box1 {
        min-width: 281px !important;
    }
}

@media (max-width:1142px) {
    .category-box1 {
        min-width: 268px !important;
    }
}

@media (max-width:1095px) {
    .category-box1 {
        min-width: 256px !important;
    }
}

@media (max-width:1049px) {
    .category-box1 {
        min-width: 243px !important;
    }
}

@media (max-width:1004px) {
    .category-box1 {
        min-width: 231px !important;
    }
}


@media (max-width:957px) {
    .category-box1 {
        min-width: 220px !important;
    }
}

@media (max-width:917px) {
    .category-box1 {
        min-width: 331px !important;
    }
}

@media (max-width:868px) {
    .category-box1 {
        min-width: 312px !important;
    }
}

@media (max-width:823px) {
    .category-box1 {
        min-width: 293px !important;
    }
}

@media (max-width:776px) {
    .category-box1 {
        min-width: 274px !important;
    }
}

@media (max-width:731px) {
    .category-box1 {
        min-width: 256px !important;
    }
}

@media (max-width:687px) {
    .category-box1 {
        min-width: 238px !important;
    }
}

@media (max-width:642px) {
    .category-box1 {
        min-width: 222px !important;
    }
}

@media (max-width:597px) {
    .category-box1 {
        min-width: 356px !important;
    }

    #categoryContainer {
        width: 75% !important;
    }
}

@media (max-width:531px) {
    .category-box1 {
        min-width: 318px !important;
    }
}

@media (max-width:474px) {
    .category-box1 {
        min-width: 279px !important;
    }
}

@media (max-width:421px) {
    .category-box1 {
        min-width: 243px !important;
    }
}

@media (max-width:368px) {
    .category-box1 {
        min-width: 225px !important;
    }
}