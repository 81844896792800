@import '../../../styles/variables.scss';

.category-box {
    border: 1px solid #E8EAED;
    width:227px;
    height: 40px;
    padding: 0 20px;
    margin: 10px;
    cursor: pointer;
}

.our-category-cont  {
    width: 90%;
}

@media (min-width:1550px) {
    .our-category-cont {
        width: 70%;
    }
}

@media(min-width:2500px) {
    .our-category-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .our-category-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .our-category-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .our-category-cont {
        width: 25%;
    }
}