@import '../../../styles//variables.scss';

.payroll-process-1{
    width: 70%;
}

@media (max-width:1550px){
    .payroll-process-1{
        width: 90%;
    }
}
@media(min-width:2500px) {
    .payroll-process-1{
        width: 45% !important;
    }
    .payroll-steps{
        width: 45% !important;
    }
}

@media(min-width:3500px) {
    .payroll-process-1{
        width: 40% !important;
    }
    .payroll-steps{
        width: 40% !important;
    }
}

@media(min-width:4500px) {
    .payroll-process-1{
        width: 30% !important;
    }
    .payroll-steps{
        width: 30% !important;
    }
}

@media(min-width:5000px) {
    .payroll-process-1{
        width: 25% !important;
    }
    .payroll-steps{
        width: 25% !important;
    }

}

@media (max-width:938px){
    .payroll-steps{
        margin: 20px 0 20px 0 !important;
        padding: 0 !important;
    }
}

@media (max-width:390px){
    .payroll-steps{
        margin: 0 !important;
    }
}