@import '../../../styles/variables.scss';

.menu-parent{
    width: 100%;
    position: relative;
    margin-top: 40px;

    .side-menu{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 13%;
        padding: 8px;
        border-right: 2px dotted #0000001F;
    }
}


.side-menu-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    color: #1F3549CC;
    font-family: #{$lexend-deca};
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    position: relative;
    margin-left: 5px;
    text-decoration: none;
    padding: 6px;
    width: 100%;
    border-radius: 8px;
    transition: color 0.3s ease-in-out;
}

// .menu-text{
//     flex: 1;
// }

.product-arrow{
    display: inline-block;
    margin-right: 15px;
    opacity: 0;
    transform: translateX(-10px);
    transition: transform 0.3s ease-in-out;
    border-width: 1px;
}

.side-menu-list:hover{
    color: #F45600;
    font-weight: 600;
    background: rgba(#F45600, 0.05);
}

.active{
    color: #F45600;
    font-weight: 600;
    background: rgba(#F45600, 0.05);
}

.active1{
    opacity: 1;
}

.side-menu-list:hover .product-arrow {
    transform: translateX(10px);
    opacity: 1;
}

// .side-menu-list::after{
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     margin: auto;
//     width: 0%;
//     content: '.';
//     color: transparent;
//     background: #F45600;
//     height: 1px;
//     transition: all .3s;
// }

// .side-menu-list:hover::after{
//     width: 100%;
//     transition: all .3s;
// }

.menu-data-list{
    list-style: none;
    width: 100%;
    height: 56vh;
    overflow-y: auto;
}

.product-menu{
    width: 90%;
}

.menu-item{
    width: 27%;
    margin: 0 50px 10px 20px;
    font-family: #{$lexend-deca};
    font-size: 12px;
    font-weight: 300;
    color: #3C3C3C99;
    cursor: pointer;
}

.menu-min-height{
    min-height: 100px;
}

.menu-name{
    font-family: #{$lexend-deca};
    font-size: 15px;
    font-weight: 600;
    color: #202124;
    margin-top: 0 !important;
}

.menu-data-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
}

@media (min-width:1550px) {
    .menu-item {
        width: 31%;
        margin: 10px 5px 10px 10px;
    }

    .side-menu {
        width: 20% !important;
    }

    .menu-data-list {
        height: 41vh;
    }
}

@media (min-width:1500px) and (max-width:1549px) {
    .menu-data-list {
        height: 49vh;
    }
}


@media (max-width:1405px) {
    .menu-item {
        width: 25%;
    }
}


@media (max-width:1275px) {
    .menu-item {
        width: 40%;
    }

    .side-menu {
        width: 20% !important;
    }
}


@media (max-width:976px) {
    .menu-item {
        width: 38%;
    }

    .side-menu {
        width: 22% !important;
    }
}


@media (max-width:885px) {
    .menu-item {
        width: 70%;
    }

    .side-menu {
        width: 30% !important;
    }
}


@media (max-width:860px) {

    .menu-parent {
        margin-top: 10px;
    }

    .menu-data-list {
        height: 53vh;
    }
}


@media (max-width:768px) {
    .menu-item {
        width: 70%;
    }

    .side-menu {
        width: 40% !important;
    }
}

@media (max-width:656px) {
    .menu-item {
        width: 100%;
    }

    .side-menu {
        width: 40% !important;
    }
}


@media (max-width:525px) {
    .menu-item {
        width: 100%;
        margin: 0 10px 15px 20px;
    }

    .side-menu {
        width: 45% !important;
    }
}

@media (max-width:475px) {
    .menu-item {
        width: 90%;
        margin: 0 5px 5px 10px;
    }

    .side-menu {
        width: 30% !important;
    }

    .product-menu {
        width: 70%;
    }

    .side-menu-list{
        font-size: 12px;
        margin-left: 10px;
    }
    
    .menu-name{
        font-size: 13px;
    }
}

@media (max-width:370px) {
    .side-menu-list {
        margin-left: 1px;
    }
}
