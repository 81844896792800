@import '../../../../styles/variables.scss';

#hr_l{
    path{
        fill: #B5B510 !important;
    }
}

.bg-light-yellow {
    background-color: #f5f5e1;

    > *:first-child {
        fill: none;
    }

    > *:last-child {
        stroke: rgba(#B5B510, 1);
    }

}

.leave-feature-img{
    width: 44%;
    border-radius: 10px;
    background-image: radial-gradient(circle #B5B5105A, #B5B5105A);
    box-shadow: 0 0 10px 1px #B5B5105A;
    margin: 0 20px 0 8px;
}


@media(max-width:1275px){
    .leave-feature-img {
        width: 70%;
    }
}


@media(max-width:576px) {
    .leave-feature-img {
        width: 90%;
    }
}


@media (max-width:390px){
    .leave-feature-img{
        width: 96%;
    }
}