@import "../../../../styles/variables.scss";
// @import '../../../../styles/helpers.scss';

.bod {
    border: 0.5px solid rgba(#000000, 0.12);
    max-height: 60px;
    margin-left: 16%;
}

.ellipse {
    border-top-left-radius: 400px;
    border-top-right-radius: 400px;
    background-image: linear-gradient(to bottom, #FFD3A5, #FFFFFF);
    height: 260px;
    width: 70%;
}
.first-name-field{
    margin-left: 25px;
}
.signup-quotes-cont{
    width: 70%;
}
.quote-bg{
    // height:55vh;
    width: 100%;
}
.sign-up-first-cont {
    
    height: 490px;
}

.quote-second-cont {
    width: 50%;
    min-height: 90vh;
}

.signup-wz-logo {
    width: 100%;
    padding-left: 5%;
    background-image: linear-gradient(to right, #FFFFFF 50%, #FEF4EA 50%);
}

.signup-form-cont {
    width: 70%;
}

.grad {
    background-image: linear-gradient(to right, #FFFFFF 50%, #FEF4EA 50%);
    width: 100%;
}

.msg-height {
     min-height: 18px;
     margin-top: 0;
     margin-bottom: 0;
}

.domain-input {
    margin-left: 24px;
}

.big-star {
    right: -50px;
    top: 20px;
}

.small-star {
    right: -60px;
    top: 50px;
}

.msg-big-star {
    right: 100px;
    top: -80px;
}

.msg-small-star {
    right: 90px;
    top: -50px;
}

.now {
    margin-left: 10px;
}
.gray-texts{
    color: rgba(#3c3c3c,  0.6);
}

@media(min-width:1550px) {
    .sign-up-first-cont {
        justify-content: center !important;
    }
    
    .sign-up-second-cont {
        justify-content: center !important;
    }

    .ellipse {
        height: 350px;
    }

    .grad {
        height: 100vh;
    }

    .domain-input {
        margin-left: 34px;
    }

    .now {
        margin-left: 15px;
    }
}

@media(min-width:2500px) {
    .grad {
        width: 70%;
        align-self: center !important;
    }

    .signup-wz-logo {
        width: 70%;
        align-self: center !important;
    }
  }

  @media(min-width:3500px) {
    .grad {
        width: 50%;
    }

    .signup-wz-logo {
        width: 50%;
        padding-left: 0%;
    }
  }

  @media(min-width:4500px) {
    .grad {
        width: 40%;
    }

    .signup-wz-logo {
        width: 40%;
    }
  }

  @media(min-width:5000px) {
    .grad {
        width: 30%;
    }

    .signup-wz-logo {
        width: 30%;
    }
  }

  
@media(max-width:1400px) {
    .bod{
        width: 50% !important;
    }
}

@media(max-width:1150px) {
    .domain-input {
        margin-left: 20px;
        
    }
}

@media(max-width:992px) {
    .grad {
        flex-direction: column !important;
    }

    .sign-up-first-cont {
        width: 100%;
    }

    .sign-up-second-cont {
        width: 100%;
      
    }

    .ellipse {
        height: 280px;
        width: 60% !important;
    }

    .domain-input {
        margin-left: 30px;
    }

    .signup-wz-logo {
        background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
    }

}
@media(max-width:860px){
    .first-name-field{
        margin-left: 0px !important;
    }
    .quote-bg{
        height:65vh;
        width: 100%;
    }
}



@media(max-width:768px) {
    .ellipse {
        height: 250px;
        width: 70% !important;
    }

    .domain-input {
        margin-left: 25px;
    }

    .sign-up-first-cont {
        display: none !important;
    }

    .signup-wz-logo {
        background-image: linear-gradient(to right, #FEF4EA, #FEF4EA) !important;
    }
}

@media(max-width:576px) {
    .ellipse {
        height: 230px;
        width: 90% !important;
    }

    .signup-svg {
        width: 60%;
    }

    .bod{
        width: 70% !important;
    }

    .domain-input {
        margin-left: 15px;
    }

    .big-star {
        right: -30px;
        top: 0px;
    }
    
    .small-star {
        right: -30px;
        top: 30px;
    }
}


@media(max-width:500px) {
    .signup-form-cont {
        width: 90%;
    }

    .domain-input {
        margin-left: 18px;
    }

    .signup-quotes-cont{
        width: 90%;
    }
}

@media(max-width:400px) {
    .ellipse {
        height: 200px;
        width: 90% !important;
    }

    .signup-svg {
        width: 70%;
    }

    .domain-input {
        margin-left: 16px;
    }

    .big-star {
        right: 0px;
        top: -30px;
    }
    
    .small-star {
        right: -10px;
        top: 0px;
    }
}