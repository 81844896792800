@import '../../../styles/variables.scss';

.hr-task-cont {
    width: 90%;
}

.inner-hr-task-cont {
    width: 45%;
}

.task-border {
    border: 0.5px solid rgba(#000000, 0.12);
}
.our-testimonial{
    margin-left: 120px;
}
@media(max-width:1560px){
    .our-testimonial{
        margin-left: 80px;
    }
}
@media(max-width:1250px){
    .our-testimonial{
        margin-left: 70px;
    }
}
@media(max-width:768px){
    .hr-avatar-cont {
        flex-direction: column !important;
    }

    .inner-hr-task-cont {
        width: 100%;
        margin-bottom: 40px;
    }
}


@media(min-width:2500px) {
    .hr-task-cont {
        width: 45% !important;
    }
}

@media(min-width:3500px) {
    .hr-task-cont {
        width: 40% !important;
    }
}

@media(min-width:4500px) {
    .hr-task-cont {
        width: 30% !important;
    }
}

@media(min-width:5000px) {
    .hr-task-cont {
        width: 25% !important;
    }
}
