@media (max-width: 997px) {
    .cycle-card {
      display: block; /* Show the card */
      width: 80%; /* Full width of the container */
      box-sizing: border-box;
      color: #999797;
      font-size: 14px;
      background-color: transparent; 
      border: none; /* Remove the border */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
      border-radius: 10px; /* Optional: Adds rounded corners */
      padding: 10px; /* Add padding for spacing inside the box */
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 70px;
      height: 90px;
     font-family:Arial, Helvetica, sans-serif;
     font-weight: 100;
    }
  }
  @media (max-width: 425px) {
    .cycle-circle-container {
      position: relative;
      width: 350px; /* Increased diameter of the circle */
      height: 350px;
    }}
    /* cycle.scss or wherever your styles are defined */

.cycle-icon {
  opacity: 0.1;
  transition: opacity 0.5s ease;
  transform: rotate(315deg);
}

.cycle-icon.appear {
  opacity: 1;
}

.cycle-bullet-container {
  display: flex;
  flex-direction: column;
}

.cycle-bullet-container .cycle-bullet-1,
.cycle-bullet-container .cycle-bullet-2,
.cycle-bullet-container .cycle-bullet-3,
.cycle-bullet-container .cycle-bullet-4,
.cycle-bullet-container .cycle-bullet-5 {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.cycle-bullet-container .cycle-bullet-1.appear,
.cycle-bullet-container .cycle-bullet-2.appear,
.cycle-bullet-container .cycle-bullet-3.appear,
.cycle-bullet-container .cycle-bullet-4.appear,
.cycle-bullet-container .cycle-bullet-5.appear {
  opacity: 1;
}

.cycle-card.active {
  display: block; /* Show the active card */
}

.cycle-card {
  display: none; /* Hide other cards */
}
