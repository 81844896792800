.progress-wrap {
    position: fixed;
    left: 20px;
    bottom: 5px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px #f4560024;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;

    &.active-progress {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    
    svg path {
        fill: none;
    }

    .mat-icon {
        position: absolute;
        top: 14px;
        left: 14px;
        stroke-width: 1px;
        stroke: #f45600;
    }
    
    svg.progress-circle path {
        stroke: #ff5722;
        stroke-width: 4;
        box-sizing: border-box;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
    }
}

@media (max-width:1130px){
    .progress-wrap{
        bottom: 52px;
        right: 3px;
    }
}
