@import '../../../styles/variables.scss';


.bgnd-clr {
    background-color: rgba(255, 239, 239, 0.6);
   
}
.custom-bg {
    background-color: rgba(241, 255, 155, 0.1);
  }
  

.faq-cont {
    width: 70%;
}

@media (max-width:1275px){
    .faq-cont{
        width: 90%;
    }

    .align {
        align-self: center !important;
        text-align: center;
    }
}


@media (min-width:1550px) {
    .faq-cont {
        width: 70%;
    }
}

@media(min-width:2500px) {
    .faq-cont {
        width: 45%;
    }
  }

  @media(min-width:3500px) {
    .faq-cont {
        width: 40%;
    }
  }

  @media(min-width:4500px) {
    .faq-cont {
        width: 30%;
    }
  }

  @media(min-width:5000px) {
    .faq-cont {
        width: 25%;
    }
  }