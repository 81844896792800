@import '../../../styles/variables.scss';

.payroll-menu{
    width: 90%; 
}

.payroll-nav{
    width: 60%;
    margin: 10px 10px 10px -5px;
}

.payroll-menu-items{
    cursor: pointer;
}

.mob-payroll-nav{
    display: none;
}

.payroll-menu-data{
    width: 90%;
}

.payroll-menu-widget{
    width: 60%;
    position: relative;
}

.payroll-menu-content{
    width: 100%;
    // padding: 100px 30px 100px 30px;
    margin-bottom: -50px;
    position: sticky;
    top: 60px;
    background-color: #ffffff;
    left: -30px;
}

.pr-top{
    width: 100%;
    position: relative;
    left: 30px;
    border-left: 2px dotted rgba(#000000, 0.2);
    padding: 100px 100px 100px 30px;
}

.payroll-left-arrow{
    position: absolute;
    top: 15px;
    left: 14px;
}

.payroll-head{
    padding: 15px;
    border-radius: 15px;
    background: #EBF6EB;
    margin-left: -78px;
}

.mob-payroll-head{
    display: none !important;
    padding: 10px 7px;
    border-radius: 15px;
    background: #EBF6EB;
    margin-left: -55px;
    width: 100%;
    gap: 120px;
}

.payroll-slideicon{
    // margin: 0 7px;
    padding-right: 7px;
}

.menu-components-wrapper{
    width: 100%;
    margin-bottom: 50px;
    scroll-behavior: smooth;
}

.each-menu{
    width: 100%;
    background-color: #ffffff;
    z-index: -10;
    padding-top: 50px;
    transform-origin: top center;
    position: relative;
    // margin-bottom: 50px;
}

.payroll-menu-title{
    width: 100%;
    padding: 20px;
}


.sticky-menu-bar{
    background-color: #ffffff; 
    box-shadow: 0 2px 5px -2px rgb(228 228 228); 
    position: sticky;
    top: 70px;
    width:100%;
    padding:12px 10px 10px 10px;
}

@media(min-width:1550px){
    .payroll-menu{
        width: 70%;
    }
    .payroll-menu-data{
        width: 70%;
    }
}

@media(min-width:2500px) {
    .sticky-menu-bar{
        width: 45% !important;
    }
    .payroll-menu-data{
        width: 45% !important
    }
    .payroll-nav{
        width: 100% !important;
    }
}

@media(min-width:3500px) {
    .sticky-menu-bar{
        width: 40% !important;
    }
    .payroll-menu-data{
        width: 40% !important
    }
}

@media(min-width:4500px) {
    .sticky-menu-bar{
        width: 30% !important;
    }
    .payroll-menu-data{
        width: 30% !important
    }
}

@media(min-width:5000px) {
    .sticky-menu-bar{
        width: 25% !important;
    }
    .payroll-menu-data{
        width: 25% !important
    }

}

@media(max-width:1200px){
    .pr-top{
        flex-direction: column !important;
        // align-items: flex-start !important;
    }
    .payroll-menu-widget{
        justify-content: center !important;
    }
    .payroll-menu-title{
        width: 100%;
        align-items: center !important;
        text-align: center;
    }
    .payroll-menu-widget{
        width: 50%;
        padding: 30px 0 30px 0;
    }
    .payroll-nav{
        width: 100%;
    }
    .mob-payroll-head{
        display: flex !important;
        margin-left: -100px;
    }
    .payroll-head{
        display: none !important;
    }
}

@media(max-width:860px){
    .mob-payroll-nav{
        display: block;
        width: 100%;
        text-align: center;
    }
    .payroll-menu-items{
        display: none;
    }
    .payroll-menu-widget{
        width: 60%;
    }
    .sticky-menu-bar{
        top: 62px;
    }
    // .mob-payroll-head{
    //     display: flex !important;
    //     margin-left: -100px;
    // }
    // .payroll-head{
    //     display: none !important;
    // }
}

@media(max-width:550px){
    .payroll-menu-widget{
        width: 70%;
        padding: 50px 0 50px 0;
    }
    .mob-payroll-head{
        gap: 0px !important;
    }
}

@media (max-width:390px) {
    .payroll-menu-content{
        top: 120px;
    }
    .pr-top{
        padding: 20px 30px 0 0;
    }
    .mob-payroll-head{
        width: 100%;
        padding: 10px;
        margin-left: -55px;
    }
    .payroll-menu-title{
        padding: 0;
    }
    .payroll-menu-widget{
        padding: 20px 0 120px 0;
    }
    .payroll-slideicon{
        padding-left: 7px;
    }
}