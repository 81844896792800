@import "../../../styles/variables.scss";

.bod {
    border: 0.5px solid rgba(#000000, 0.12);
    max-height: 60px;
    margin-left: 16%;
}

.ellipse {
    border-top-left-radius: 400px;
    border-top-right-radius: 400px;
    background-image: linear-gradient(to bottom, #FFD3A5, #FFFFFF);
    height: 260px;
    width: 70%;
}
.testimonial-section {
    // line-height: 15px;
}
.signup-quotes-cont{
    width: 70%;
}

.sign-up-first-cont {
    width: 50%;
    height: 100% !important;
    position: relative;
}
.top-design{
position: absolute;
right: -110px;
top:0;
}
.bottom-design{
    position: absolute;
    left: -20px;
    bottom:0;
    }
    .logo-container-signup{
        position: absolute;
        top:15px;
        left:10px;
    }
.sign-up-second-cont {
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    min-height: 100vh;
}
.center-certificates {
    height: 100vh;
    margin-left: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    border-right:1px solid white;
}

.inner-certificates {
    background-color: white;
    border-radius: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; 
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
}

.signup-wz-logo {
    width: 100%;
    padding-left: 5%;
    background-image: linear-gradient(to right, #FFFFFF 50%, #FEF4EA 50%);
    // background-color: #ffffff;
}

.signup-form-cont {
    width: 70%;
    // background-color: #ffffff;
}

.grad {
    background-image: linear-gradient(to right, #FFFFFF 50%, #FEF4EA 50%);
    width: 100%
}

.msg-height {
     min-height: 18px;
     margin-top: 0;
     margin-bottom: 0;
}

.domain-input {
    margin-left: 24px;
}

.big-star {
    right: -50px;
    top: 20px;
}

.small-star {
    right: -60px;
    top: 50px;
}

.msg-big-star {
    right: 100px;
    top: -80px;
}

.msg-small-star {
    right: 90px;
    top: -50px;
}
.signup-text{
    position: absolute;
    top:30px;
    left:10px;
}
.testimonial-section{
    // margin-top: 20px;
}
.now {
    margin-left: 10px;
}
.desc-container{
    // margin-top: 17px;
}
.success-download-container{
    bottom: 50px;
    left: 0;
    right: 0;
}
.not-success-download-container{
    bottom: 0px;
    left: 0;
    right: 0;
}

@media(min-width:1550px) {
    .sign-up-first-cont {
        justify-content: center !important;
    }
    
    .sign-up-second-cont {
        justify-content: center !important;
        
    }

    .ellipse {
        height: 350px;
    }

    .grad {
        height: 100vh;
    }

    .domain-input {
        margin-left: 34px;
    }

    .now {
        margin-left: 15px;
    }
}

@media(min-width:2500px) {
    .grad {
        width: 70%;
        align-self: center !important;
    }

    .signup-wz-logo {
        width: 70%;
        align-self: center !important;
    }
  }

  @media(min-width:3500px) {
    .grad {
        width: 50%;
    }

    .signup-wz-logo {
        width: 50%;
        padding-left: 0%;
    }
  }

  @media(min-width:4500px) {
    .grad {
        width: 40%;
    }

    .signup-wz-logo {
        width: 40%;
    }
  }

  @media(min-width:5000px) {
    .grad {
        width: 30%;
    }

    .signup-wz-logo {
        width: 30%;
    }
  }
  @media(max-width:1770px) {
    .bottom-design-svg{
        width: 350px !important;
        height: 260px !important;
    }
    .bottom-design{
      left: -10px !important;
    }
}
  
@media(max-width:1550px) {
    .signup-form-cont{
     margin-top: 50px !important;
    }
    .desc-container{
        // margin-top: 35px !important;
    }
}
@media(max-width:1400px) {
    .bod{
        width: 50% !important;
    }
}
@media(max-width:1220px) {
    .signup-text{
        top:20px !important;
        left:-10px !important;
    }
}


@media(max-width:1150px) {
    .domain-input {
        margin-left: 20px;
    }
    .inner-certificates {
        padding: 5px !important;
        gap: 10px !important;  
    }
    .certificate-icons{
        width: 30px;
        height: 30px;
    }
    .center-certificates{
        margin-left: -18px !important;
    }
    .top-design-svg{
        width: 250px !important;
        height: 160px !important;
    }
    .top-design{
        right: -100px !important;
    }
    .bottom-design-svg{
        width: 250px !important;
        height: 185px !important;
    }
    .big-star{
        right: -40px !important;
        top: 10px !important;
    }
    .small-star {
        right: -50px !important;
        top: 30px !important;
    }
}

@media(max-width:992px) {
    .signup-flex-container {
        flex-direction: column !important;
        height: 120vh !important;
    }
.not-success-download-container{
    bottom: -25px !important;
}
    .sign-up-first-cont {
        width: 100%;
    }

    .sign-up-second-cont {
        width: 100%;
    }

    .ellipse {
        height: 280px;
        width: 60% !important;
    }

    .domain-input {
        margin-left: 30px;
    }

    .signup-wz-logo {
        background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
    }
    .center-certificates{
        display: none !important;
    }
    .autoplay-carousel{
        display: none !important;
    }
}
@media(max-width:860px) {
    .signup-flex-container {
        height: 130vh !important;
    }
    .signup-form-cont{
        margin-top: 30px !important;
    }
    .success-download-container{
        bottom: 70px;
        left: 0;
        right: 0;
    }
    .not-success-download-container{
        bottom: -60px !important;
    }
}


@media(max-width:768px) {
    .signup-flex-container {
      
        height:150vh !important;
    }
    .ellipse {
        height: 250px;
        width: 70% !important;
    }

    .domain-input {
        margin-left: 25px;
    }

    .sign-up-first-cont {
        display: none !important;
    }

    .signup-wz-logo {
        background-image: linear-gradient(to right, #FEF4EA, #FEF4EA) !important;
    }
}
@media(max-width:640px) {
    .signup-text{
        top:20px !important;
        left:10px;
    }
    .signup-form-cont{
        margin-top: 10px !important;
    }
}

@media(max-width:576px) {
    .ellipse {
        height: 230px;
        width: 90% !important;
    }

    .signup-svg {
        width: 60%;
    }

    .bod{
        width: 70% !important;
    }

    .domain-input {
        margin-left: 15px;
    }

    .big-star {
        right: -30px;
        top: 0px;
    }
    
    .small-star {
        right: -30px;
        top: 30px;
    }
}


@media(max-width:500px) {
    .signup-form-cont {
        width: 90%;
    }

    .domain-input {
        margin-left: 18px;
    }

    .signup-quotes-cont{
        width: 90%;
    }
    .big-star {
        right: -8px !important;
        top: 0px !important;
    }
    
    .small-star {
        right: -15px !important;
        top: 20px !important;
    }
}

@media(max-width:400px) {
    .ellipse {
        height: 200px;
        width: 90% !important;
    }

    .signup-svg {
        width: 70%;
    }

    .domain-input {
        margin-left: 16px;
    }

    .big-star {
        right: -8px !important;
        top: 0px !important;
    }
    
    .small-star {
        right: -15px !important;
        top: 20px !important;
    }
    .cover-text{
        display: none !important;
    }
    .autoplay-carousel{
        display: none !important;
    }
    // .not-success-download-container{
    //     bottom: 120px !important;
       
    // }
    // .signup-form-cont {
    //     min-height: 110vh !important;
    // }

  
    // .signup-flex-container {
    //     flex-direction: column !important;
    //     height: 120vh !important;
    // }
}

/* Add this CSS to make the bottom part scrollable */
.signup-flex-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensures the container takes full viewport height */
  }
  
  .sign-up-first-cont {
    flex: 1; /* This takes up the remaining space */
    overflow: hidden; /* Prevents scrolling for the first half */
  }
  
  .sign-up-second-cont {
    flex: 1;
    overflow-y: auto; /* Makes this section scrollable */
    overflow-x: hidden;
   //padding-bottom: 20px; /* Optional: Add some padding for spacing */
    height: 100%; /* Ensure it takes up the remaining space after the first section */
  }
  
  /* Ensure that the second section does not overflow the viewport */
//   .signup-form-cont {
//     min-height: 100%; /* Ensures the form stays within the scrollable area */
//   }