  .card-partnership {
      border: 1px solid #ddd; /* Adding border to the card */
      border-radius: 8px; /* Rounded corners */
      background-color: #f9f9f9; /* Light gray background */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
      
      display: flex;
      flex-direction: column;
      justify-content: space-between; /* Ensure content is distributed */
      
      height: auto; /* Fixed height to ensure all cards are of equal height */
      padding: 20px;
      box-sizing: border-box; /* Include padding in the total width/height calculation */
      background-color: #ecf6ff;
      
      position: relative; /* To allow positioning of the pseudo-element */
  }

  /* Adding the small arc-shaped background at the bottom left */
  .card-partnership::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20%; /* Ensure the arc spans across the full width */
    height: 20px; /* Adjust this value for arc size */
    background-color: #54c4bc; /* Arc background color */
    border-top-left-radius: 30%; /* Create a curved effect */
    border-top-right-radius: 200%; /* Create a curved effect */
  }

    
    .card-partnership p {
      font-size: 18px;
      text-align: center;
      margin: 0;
      padding: 8px;
      flex-grow: 1; /* Make the paragraph grow and fill available space */
    }
    .card-partnership .para-small {
      font-size: 14px;
    }/* Define the animation */
    /* Initially hidden, and will fade in on scroll */
  .card-div {
    opacity: 0;
    transform: translateY(20px); /* Slightly position the card lower */
    transition: opacity 1s ease, transform 1s ease;
  }

  /* Add this class when the card is in view */
  .animate-card {
    opacity: 1;
    transform: translateY(0); /* Move the card to its original position */
  }
  .heading-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%; // Ensure it takes full width for centering
    
    // Media query for devices with width less than 997px
    @media (max-width: 997px) {
      text-align: center; // Ensure text is centered on smaller screens
      margin-left: 15px;
    }
  }@media (min-width: 766px) and (max-width: 1200px) {
    .card-div {
      width: 100%; /* Make the card div take up full width */
      display: flex;
      justify-content: center; /* Center the card horizontally */
      margin: 0 auto; /* Center the card in the row */
    }
  
    .col-md-3 {
      width: 70%; /* One card per row taking up the full width */
      padding: 0 10px; /* Add small padding for spacing between cards */
    }
  
    .card-partnership {
      width: 100%; /* Make the card occupy the full width of the container */
      max-width: 100%; /* Prevent the card from overflowing */
      margin: 0 auto; /* Center the card */
      box-sizing: border-box; /* Include padding and border in width calculation */
      display: flex;
      flex-direction: column;
      justify-content: space-between; /* This will help ensure equal spacing inside */
      height: 300px; /* Fixed height to ensure all cards are equal in height */
    }
  
    .card-partnership img {
      width: 100%; /* Ensure the image takes the full width of the card */
      height: auto; /* Maintain aspect ratio of the image */
      margin-bottom: 20px; /* Adds space below the image */
    }
  
    .card-partnership p {
      text-align: center;
      font-size: 18px;
      margin-bottom: 20px; /* Adds space between paragraphs and the bottom */
      flex-grow: 1; /* Ensure the paragraph takes available space */
    }
  
    /* Equalize bottom margins between cards */
    .card-div + .card-div {
      margin-top: 20px; /* Adjust margin between rows to equalize bottom spacing */
    }
  
    .col-md-1 {
      display: none; /* Hide empty columns if used */
    }
  }
  