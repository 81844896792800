@import '../../../styles/variables.scss';

.product-menu-card{
    width: 100%;
    background-color: #DCF7FF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 0 20px;
}

.cont-card{
    width: 75%;
}

.para-card{
    font-family: #{$sora};
    font-size: 13px;
    font-weight: 800;
    color: #00B2A9;
}


@media (max-width:768px) {
    .product-menu-card {
        flex-direction: column !important;
        padding: 20px;
    }

    .cont-card {
        width: 100%;
        align-items: center;
    }

    .center {
        text-align: center;
    }
}
