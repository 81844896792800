
.apexcharts-menu-icon {
    display: none;
}

.area-chart {
    width: 144px;
    height: 124px;
}

@media (max-width:440px) {
    .area-chart {
        width: 144px;
        height: 114px;
    }

    .move-area {
        margin-top: -10px;
    }
}