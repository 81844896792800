@import '../../../styles/variables.scss';


.invester-cont {
    width: 90%;
}

.our-invester-cont {
    width: 45%;
}

.investor {
    width: 45%;
}

.investor-btn {
    width:18%
}

@media(max-width:992px){
    .invester-cont {
       flex-direction: column !important;
       justify-content: start !important;
       align-items: start !important;
    }

    .our-invester-cont {
        width: 90%;
    }
    
    .investor {
        width: 90%;
        margin-top: 10px;
    }
    
    .investor-btn {
        margin-top: 20px;
        width:30%
    }
}

@media(max-width:576px){
    
    .investor-btn {
        width:45%
    }
}

@media(max-width:440px){
    
    .investor-btn {
        width:55%
    }
}



@media(min-width:1550px){
    .invester-cont {
        width: 70%;
    }
}


@media(min-width:2500px) {
    .invester-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .invester-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .invester-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .invester-cont {
        width: 25%;
    }
}