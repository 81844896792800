@import '../../../../styles/variables.scss';

.modules-cont-hr {
    width: 100%;
    background-image: linear-gradient(to right , #ecffec, #f8f8f8);
    max-height: 100px;
}

.modules-card {
    background-color: #ffffff;
    border: 1px solid #E8EAED;
    width: 20%;
    min-height: 160px;
    max-height: 250px;
    margin-top: -60px;
}


@media(min-width:2500px) {
    .modules-cont-hr {
        width: 45% !important;
        align-self: center !important;
    }
}

@media(min-width:3500px) {
    .modules-cont-hr {
        width: 40% !important;
    }
}

@media(min-width:4500px) {
    .modules-cont-hr {
        width: 30% !important;
    }
}

@media(min-width:5000px) {
    .modules-cont-hr {
        width: 25% !important;
    }
}


@media (max-width:1275px) {
    .modules-card {
        width: 22%;
    }
}

@media (max-width:1000px) {
    .modules-card {
        width: 90% !important;
        margin-bottom: 20px;
        margin-top: 0;
    }

    .modules-cont-hr {
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        max-height: 750px;
        margin-top: 20px !important;
        padding-top: 20px;
    }
}

@media (max-width:768px) {
    .modules-cont-hr {
        margin-top: -20px !important;
    }
}