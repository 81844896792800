@import '../../../styles/variables.scss';

.privacy-policy{
    width: 90%;
    font-family: #{$lexend-deca};
    border-radius: 10px;
background-image: radial-gradient(circle #FF57223A, #FF57223A);
background-color: #ffffff;
box-shadow: 0 0 10px 1px #FF57223A;
margin-top: -150px;
z-index: 1;
padding:20px;
}

.privacy-info{
    font-weight: 600;
}
.product-inner-cont-cookie{
    height:350px
}
// .product-heading{
//     margin-top: -90px;
// }
.product-cookie-heading{
    margin-top: -90px;
}
.privacy-table{
    table, th, td {
        border: 1px solid black;
        padding: 10px;
        text-align: center;
    }
    padding: 20px;
    // overflow-x: auto;
}
.privacy-table table {
    width: 100%; 
    border-collapse: collapse;
}

.step-into-card-cookie{
    width:580px;
    height:350px;
}

@media (min-width:1550px){
    .privacy-policy{
        width:70%;
    }
}

@media(min-width:2500px) {
    .privacy-policy {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .privacy-policy {
        width: 40%;
    }

}

@media(min-width:4500px) {
    .privacy-policy {
        width: 30%;
    }

}

@media(min-width:5000px) {
    .privacy-policy {
        width: 25%;
    }

}
@media(max-width:1256px){
    .product-cookie-heading{
        margin-top: -50px;
    }
}
.second-cookie-s{
    width: 50%;
    margin: 20px 0 0 20px;
} 
@media(max-width:1257px){

    .second-cookie-s{
        width: 90%;
        margin: 0;
    }
}
@media (max-width: 820px) {
    .privacy-table th, 
    .privacy-table td {
        font-size: 14px; 
        padding: 8px; 
    }
}

@media (max-width: 768px) {
    .privacy-table th, 
    .privacy-table td {
        font-size: 10px; 
        padding: 3px; 
    }
}
@media (max-width: 580px) {
    .privacy-table th, 
    .privacy-table td {
        font-size: 10px; 
        padding: 3px !important; 
    }
}
@media (max-width: 500px) {
    .privacy-table th, 
    .privacy-table td {
        font-size: 8px; 
        padding: 2px !important; 
    }
}
@media(max-width:490px){
   
 
    .second-cookie-s{
        width: 100%;
    }
}
@media(max-width:860px){

    .product-inner-cont-cookie{
        margin-top: 50px;
    }
}