// @import '../../../styles//variables.scss';


// .pie-chart {
//     width:170px;
//     height: 148px;
// }
// // .move-normal{
// //     position: absolute;
// //     bottom: -3px;
// //     left: 30px;
// // }
// .hide-mb-pie, .show-mb-pie {
//     margin-bottom: 20px; /* Adds space inside the container */
// }
// .hide-mb-pie {
//     display: block;
//     margin-bottom: 10px;

// }

// .show-mb-pie {
//     display: none;
// }

// @media (max-width:576px) {
//     .pie-chart {
//       width: 150px !important;
//     }
// }

// @media (max-width:440px) {
//     .pie-chart {
//       width: 130px !important;
//       height: 130px !important;
      
//     }
//     .pie-chart .hide-mb-pie {
//         display: none !important;
//     }
//     // .show-mb-pie {
//     //     display: block !important;
//     // }

//     // .hide-mb-pie {
//     //     display: none !important;
//     // }
//     .hide-mb-pie {
//         display:none !important;
//         margin-bottom: 10px;
//     }
//     .move-up {
//         // position: absolute;
//         // bottom: -15px;
//         // left: -55px;
//         // margin-bottom: 10px;
//     }
// }

@import '../../../styles//variables.scss';


.pie-chart {
    width:170px;
    height: 148px;
}


.hide-mb-pie {
    display: block;
}

.show-mb-pie {
    display: none;
}

@media (max-width:576px) {
    .pie-chart {
      width: 150px !important;
    }
}

@media (max-width:440px) {
    .pie-chart {
      width: 130px !important;
      height: 135px !important;
    }

    .show-mb-pie {
        display: block;
    }

    .hide-mb-pie {
        display: none;
    }

    .move-up {
        position: absolute;
        bottom: 2px;
        left:-55px;
    }
  
}


