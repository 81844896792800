@import '../../../../styles/variables.scss';

#Leftw-l{
    path {
        fill: rgba(#B5B510, 0.4)!important;
    }
}
#leave-top{
    path {
        fill: rgba(#B5B510, 0.4)!important;
    }
}
#leave-bottom{
    path {
        fill: rgba(#B5B510, 0.4)!important;
    }
}

.leave-top{
    top: 15px;
    // z-index: -1;
}
.leave-bottom{
    bottom: 55px;
    // z-index: -1;
}


.leave-img{
    width: 100% ;
    z-index: 1;
}

.bgnd-leave {
    width: 355px;
    height: 300px;
    background-image: radial-gradient(circle, #B5B5103A, #B5B5103A);
    background-size: cover;
    box-shadow: 0 0 50px 40px #B5B5103A;
    opacity: 0.4;
}

@media(max-width:780px){
   
    .bgnd-leave {
        width: 355px;
        height: 200px;
    }
}

@media(max-width:580px){
   
    .bgnd-leave {
        width: 280px;
        height: 120px;
    }
}

@media(max-width:490px){
   
    .bgnd-leave {
        width: 260px;
        height: 100px;
    }
}

@media(max-width:390px){
   
    .bgnd-leave {
        width: 230px;
        height: 130px;
    }

    .leave-top{
        top: -55px;
        z-index: -1;
    }
    .leave-bottom{
        bottom: -16px;
        z-index: -1;
    }
}