@import '../../../styles/variables.scss';

.mob-blog-menu {
    width:100%;
    z-index: 5;
    display: none;
}

.menu-blog {
    color:white;
}

.primary-blog{
    background:#F45600;
    width: 50px;
    height: 50px;
}

.signup{
    height: 50px;
    width: 100%;
    background: #1A2D48;
}

.mob-get{
    color:white;
    text-decoration: none;
}

  @media (max-width:1130px) {

    .mob-blog-menu {
        display: block !important;
    }
  }
  