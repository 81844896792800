@import '../../../styles/variables.scss';

.mob-menu {
    width:100%;
    z-index: 5;
    display: none;
}

.menu {
    color:white;
}

.primary{
    background:#F45600;
    width: 50px;
    height: 50px;
}

.signup{
    height: 50px;
    width: 100%;
    background: #1A2D48;
}

.mob-get{
    color:white;
    text-decoration: none;
}
@media (max-width:1010px) {
    .mob-menu {
        display: block !important;
        }
    .menu {
        display: block !important;
    }
}