@import '../../../../styles/variables.scss';

#line{
    rect{
        fill: #227AFF;
    }
}

.ess-merits{
    width: 100%;
    background-image: linear-gradient(to right, #66a1fb4a, #c2d4ee4a);
}
