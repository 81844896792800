@import '../../../../styles/variables.scss';

.bg-light-ess {
    background-color: #e5eef9;

    > *:first-child {
        fill: none;
    }

    > *:last-child {
        stroke: #227AFF9A;
    }

}

#hr_e{
    path{
        fill: #227AFF !important;
    }
}

.ess-feature-img{
    width: 44%;
    border-radius: 20px;
    background-image: radial-gradient(circle #227AFF5A, #227AFF5A);
    box-shadow: 0 0 10px 1px #227AFF5A;
    margin: 0 20px 0 8px;
}

.img-s{
    height: 70vh;
    border-radius: 10px;
    background-image: radial-gradient(circle #227AFF5A, #227AFF5A);
    box-shadow: 0 0 10px 1px #227AFF5A;
    margin: 0 180px 0 20px;
    transform: rotate(15deg);
}

@media(min-width:1275px){
    .img-s{
        height: 50vh;
        margin-bottom: 20px;
    }
}

@media(max-width:1275px){
    .ess-feature-img {
        width: 70%;
    }

    .img-s{
        height: 40vh;
        margin: 0 70px 10px 100px;
    }
}

@media(max-width:570px){
    .img-s{
        height: 30vh;
    }

    .ess-feature-img {
        width: 90%;
    }
}

@media (max-width:390px){
    .ess-feature-img{
        width: 96%;
    }
}