@import '../../../styles/variables.scss';

.blog-items-cont{
    width:30%;
}

@media (max-width:1275px) {
    .blog-items-cont{
        width:90%;
        margin-bottom: 30px;
    }
}