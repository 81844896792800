@import "variables.scss";
/* padding and margin  m-l-value * 5 */

@for $i from -25 through 25 {
    .m-l-#{$i * 5} {
      margin-left: #{$i * 5}px;
    }
  
    .m-t-#{$i} {
      margin-top:#{$i}px;
    }
  
    .m-l-#{$i * 2} {
      margin-left: #{$i * 2}px;
    }
  
    .m-l-#{$i} {
      margin-left: #{$i}px;
    }
  
    .m-t-#{$i * 5} {
      margin-top: #{$i * 5}px;
    }
  
    .m-r-#{$i * 5} {
      margin-right: #{$i * 5}px;
    }
  
    .m-b-#{$i * 5} {
      margin-bottom: #{$i * 5}px;
    }
  
  
    // padding 
    .p-y-#{$i} {
      padding-top: #{$i}px;
      padding-bottom: #{$i}px;
    }
  
    .p-x-#{$i} {
      padding-left: #{$i}px;
      padding-right: #{$i}px;
    }
  
  }
  
  .margin-0 {
    margin: 0;
  }
  
  @for $i from 0 through 25 {
    .p-l-#{$i * 5} {
      padding-left: #{$i * 5}px;
    }
  
    .p-t-#{$i * 5} {
      padding-top: #{$i * 5}px;
    }
  
    .p-r-#{$i * 5} {
      padding-right: #{$i * 5}px;
    }
  
    .p-b-#{$i * 5} {
      padding-bottom: #{$i * 5}px;
    }
  }
  
  @for $i from -150 through 200 {
    .mrn-#{$i} {
      margin:#{$i + 'px'} !important;
    }
  
    .mal-#{$i} {
      margin-left:#{$i + 'px'} !important;
    }
  
    .mar-#{$i} {
      margin-right:#{$i + 'px'} !important;
    }
  
    .mat-#{$i} {
      margin-top:#{$i + 'px'} !important;
    }
  
    .mab-#{$i} {
      margin-bottom:#{$i + 'px'} !important;
    }
  
    .lh-#{$i} {
      line-height:#{$i + 'px'} !important;
    }
  
    // padding
    .pa-#{$i} {
      padding:#{$i + 'px'} !important;
    }
  
    .pal-#{$i} {
      padding-left:#{$i + 'px'} !important;
    }
  
    .par-#{$i} {
      padding-right:#{$i + 'px'} !important;
    }
  
    .pat-#{$i} {
      padding-top:#{$i + 'px'} !important;
    }
  
    .pab-#{$i} {
      padding-bottom:#{$i + 'px'} !important;
    }
  
    @if($i <=100) {
      .w-#{$i} {
        width:#{$i + '%'} !important;
      }
  
      .h-#{$i} {
        height:#{$i + '%'} !important;
      }
  
      .top-percentage-#{$i} {
        top: #{$i + '%'} !important;
      }
  
      .bottom-percentage-#{$i} {
        bottom: #{$i + '%'} !important;
      }
  
      .right-percentage-#{$i} {
        right: #{$i + '%'} !important;
      }
  
      .left-percentage-#{$i} {
        left: #{$i + '%'} !important;
      }
    }
  
    .top-#{$i} {
      top:#{$i + 'px'} !important;
    }
  
    .bottom-#{$i} {
      bottom:#{$i + 'px'} !important;
    }
  
    .right-#{$i} {
      right:#{$i + 'px'} !important;
    }
  
    .left-#{$i} {
      left:#{$i + 'px'} !important;
    }
  
    .top-rem-#{$i} {
      top: #{$i + 'rem'} !important;
    }
  
    .bottom-rem-#{$i} {
      top: #{$i + 'rem'} !important;
    }
  
    .right-rem-#{$i} {
      top: #{$i + 'rem'} !important;
    }
  
    .left-rem-#{$i} {
      top: #{$i + 'rem'} !important;
    }
    
    /* z-index */
    .z-index-#{$i} {
      z-index: #{$i} !important;
    }
  }
  
  .z-index-unset {
    z-index: unset !important;
  }
  
  /* padding and margin in px values ml- */
  
  @for $i from 6 through 200 {
  
    .m-#{$i} {
      margin:#{$i + 'px'} !important;
    }
  
    .ml-#{$i} {
      margin-left:#{$i + 'px'} !important;
    }
  
    .mr-#{$i} {
      margin-right:#{$i + 'px'} !important;
    }
  
    .mt-#{$i} {
      margin-top:#{$i + 'px'} !important;
    }
  
    .mb-#{$i} {
      margin-bottom:#{$i + 'px'} !important;
    }
  
    .mx-#{$i} {
      margin-left: #{$i + 'px'} !important;
      margin-right: #{$i + 'px'} !important;
    }
  
    .my-#{$i} {
      margin-top:#{$i + 'px'} !important;
      margin-bottom:#{$i + 'px'} !important;
    }
  
    // padding
    .p-#{$i} {
      padding:#{$i + 'px'} !important;
    }
  
    .pl-#{$i} {
      padding-left:#{$i + 'px'} !important;
    }
  
    .pr-#{$i} {
      padding-right:#{$i + 'px'} !important;
    }
  
    .pt-#{$i} {
      padding-top:#{$i + 'px'} !important;
    }
  
    .pb-#{$i} {
      padding-bottom:#{$i + 'px'} !important;
    }
  
    .px-#{$i} {
      padding-left: #{$i + 'px'} !important;
      padding-right: #{$i + 'px'} !important;
    }
  
    .py-#{$i} {
      padding-top:#{$i + 'px'} !important;
      padding-bottom:#{$i + 'px'} !important;
    }
  
  }
  
  @for $i from 0 through 1200 {
    .w-px-#{$i} {
      width:#{$i + 'px'} !important;
    }
  
    .h-px-#{$i} {
      height:#{$i + 'px'} !important;
    }
  
    .w-max-#{$i} {
      max-width: #{$i}px !important;
    }
  
    .h-max-#{$i} {
      max-height: #{$i}px !important;
    }
  
    .w-min-#{$i} {
      min-width: #{$i}px !important;
    }
  
    .w-max-percentage-#{$i} {
      max-width: #{$i}+'%' !important;
    }
  
    .w-min-percentage-#{$i} {
      min-width: #{$i}+'%' !important;
    }
  
    .h-min-#{$i} {
      min-height: #{$i}px !important;
    }
  
    .height-rem-#{$i} {
      height: #{$i + 'rem'} !important;
    }
  
    .width-rem-#{$i} {
      width: #{$i + 'rem'} !important;
    }
  
    @if $i < 500 {
      .h-cal-#{$i} {
        height: calc(100vh - #{$i}px) !important;
      }
  
      .h-max-cal-#{$i} {
        max-height: calc(100vh - #{$i}px) !important;
      }
  
      .h-min-cal-#{$i} {
        min-height: calc(100vh - #{$i}px) !important;
      }
  
      .h-min-percentage-#{$i} {
        min-height: #{$i} + '%' !important;
      }
  
      .h-max-percentage-#{$i} {
        max-height: #{$i} + '%' !important;
      }
    }
  
    @if $i <=360 {
      .rotate-#{$i} {
        transform: rotate(#{$i}deg);
      }
    }
  
    .grid-repeat-#{$i} {
      display: grid;
      grid-template-columns:repeat(auto-fit, minmax(#{$i}px, 1fr))
    }
  }
  
  .w-fit-content {
    width: fit-content !important;
  }
  
  .w-max {
    width: max-content !important;
  }
  
  .h-max {
    height: max-content !important;
  }
  
  @for $i from 0 through 80 {
    .text-#{$i} {
      font-size: #{$i * 0.0625 + 'rem'} !important;
    }
  
    @if($i > 5) {
      .rounded-#{$i} {
        border-radius: #{$i}px !important;
      }
    }
  }
  
  
  @for $i from 0 through 25 {
    .margin-#{$i * 5} {
      margin: #{$i * 5}px;
    }
  
    .padding-#{$i * 5} {
      padding: #{$i * 5}px;
    }
  
    .row-repeat-#{$i} {
      grid-template-rows: repeat($i, 1fr) !important;
    }
  
    .column-repeat-#{$i} {
      grid-template-columns: repeat($i, 1fr) !important;
    }
  }
  
  .padding-0 {
    padding: 0;
  }
  
  @for $i from 5 through 49 {
    .font-#{$i + 1} {
      font-size: #{$i + 1}px !important;
    }
  }
  
  @for $i from 1 through 9 {
    .font-weight-#{$i} {
      font-weight: #{$i * 100} !important;
    }
  
    .transition-#{$i} {
      transition:all #{'0.'+$i}s ease-in-out;
    }
  
    .animation-delay-#{$i} {
      animation-delay: #{'0.' + $i}s;
    }
  }
  
  .align-left {
    text-align: left !important;
  }
  
  .align-center {
    text-align: center !important;
  }
  
  .align-right {
    text-align: right !important;
  }
  
  .align-justify {
    text-align: justify !important;
  }
  
  .justify-content-unset {
    justify-content: unset !important;
  }
  
  .no-resize {
    resize: none;
  }
  
  .font-bold {
    font-weight: 500;
  }
  
  .font-italic {
    font-style: italic;
  }
  
  .font-underline {
    text-decoration: underline;
  }
  
  .font-line-through {
    text-decoration: line-through;
  }
  
  .font-overline {
    text-decoration: overline;
  }
  
  .pointer-none {
    cursor: not-allowed !important;
  }
  
  .drag-handle {
    cursor: move;
  }
  
  .d-contents {
    display: contents !important;
  }
  
  .d-inherit {
    display: inherit !important;
  }
  
  .position-unset {
    position: unset !important;
  }
  
  .place-content-center {
    place-content: center !important;
  }
  
  .place-content-end {
    place-content: end !important;
  }
  
  .place-content-start {
    place-content: start !important;
  }
  
  .place-items-center {
    place-items: center !important;
  }
  
  .place-items-end {
    place-items: end !important;
  }
  
  .place-items-start {
    place-items: start !important;
  }
  
  .bg-inherit {
    background: inherit !important;
  }
  
  .bg-none {
    background: none !important;
  }
  
  .block-header {
  
    h2 {
      margin: 0 !important;
      color: var(--wz-gray-666 )!important;
      font-weight: normal;
      font-size: 1.375rem;
      line-height: 46px;
  
      small {
        display: block;
        font-size: 0.75rem;
        margin-top: 8px;
        color: var(--wz-gray-888);
  
        a {
          font-weight: bold;
          color: var(--wz-gray-777);
        }
      }
    }
  }
  
  // @each $key,
  // $val in $colors {
    // .bg-#{$key} {
      // background-color: $val !important;
      // color: $white;
  // 
      // .content {
  // 
        // .text,
        // .number {
          // color: $white !important;
        // }
      // }
    // }
  // }
  
  // @each $key,
  // $val in $linear-colors {
    // .l-bg-#{$key} {
      // background: $val !important;
      // color: $white;
  // 
      // .content {
  // 
        // .text,
        // .number {
          // color: $white !important;
        // }
      // }
    // }
  // }

  @each $key,
  $val in $wz-font-family {
    .family-#{$key} {
      font-family: $val !important;
    }
  }
  
  @each $key,
  $val in $wz-colors {
    .col-#{$key} {
      color: $val !important;
    }
  
    .bg-col-#{$key} {
      background-color: $val !important;
    }
  }
  
  @each $key,
  $val in $wz-theme-colors {
    .col-#{$key} {
      color: $val !important;
    }
  
    .bg-col-#{$key} {
      background-color: $val !important;
    }
  
    .border-#{key} {
      border: 1px solid $val !important;
    }
  }
  
  @each $key,
  $val in $wz-badge-colors {
    .badge-#{$key} {
      background-color: $val !important;
    }
  }
  
  @each $key,
  $val in $wz-emp-badge-colors {
    .emp-#{$key} {
      background-color: $val !important;
    }
  }
  
  @for $i from 0 through 100 {
    .width-per-#{$i} {
      width: round(percentage(calc($i/100)));
    }
  
    .max-width-per-#{$i} {
      max-width: round(percentage(calc($i/100)));
    }
  
    .vw-#{$i} {
      width: #{$i}vw !important;
    }
  
    .vh-#{$i} {
      height: #{$i}vh !important;
    }
  }
  
  @for $i from 0 through 5 {
    .scrollbar-width-#{$i} {
      &::-webkit-scrollbar {
        width: #{$i}px !important;
      }
    }
  }
  
  .h-fit-content {
    height: fit-content !important;
  }
  
  .bg-gray-dark {
    background-color: $gray-800;
    color: $white;
  }
  
  .bg-gray-100 {
    background-color: $gray-100;
    color: $gray-800;
  }
  
  .bg-gray-200 {
    background-color: $gray-200;
    color: $gray-800;
  }
  
  .bg-gray-300 {
    background-color: $gray-300;
    color: $gray-800;
  }
  
  .gray-rgb-300 {
    color: rgba(0, 0, 0, 0.30) !important
  }
  
  .bg-gray-400 {
    background-color: $gray-400;
    color: $gray-800;
  }
  
  .bg-gray-500 {
    background-color: $gray-500;
    color: $white;
  }
  
  .bg-gray-600 {
    background-color: $gray-600;
    color: $white;
  }
  
  .bg-gray-700 {
    background-color: $gray-700;
    color: $white;
  }
  
  .bg-gray-800 {
    background-color: $gray-800;
    color: $white;
  }
  
  .bg-gray-900 {
    background-color: $gray-900;
    color: $white;
  }
  


  color
  
  
  
  .color-primary {
    color: $primary !important;
  }
  
  .color-secondary {
    color: $secondary !important;
  }
  
  .color-tertiary {
    color: $tertiary !important;
  }
  
  .color-quaternary {
    color: $quaternary !important;
  }
  
  .color-delete {
    color: $delete !important;
  }
  
  .color-white {
    color: $white !important;
  }
  
  .error-text {
    color: $error !important;
  }


  
  BG Color


  .bg-primary {
    background: $primary !important;
  }
  
  .bg-secondary {
    background: $secondary !important;
  }
  
  .bg-tertiary {
    background: $tertiary !important;
  }
  
  .bg-quaternary {
    background: $quaternary !important;
  }
  
  .bg-delete {
    background: $delete !important;
  }
  
  .opacity {
    opacity: 1 !important;
  }
  


  display grid classes


  .justify-items-baseline {
    justify-items: baseline !important;
  }
  
  .justify-items-start{
     justify-items: start;
  }
  
  .justify-items-end {
    justify-items: end !important;
  }
  
  .justify-items-center {
    justify-items: center !important;
  }
  
  .align-content-between {
    align-content: space-between !important;
  }
  
  .align-content-center {
    align-content: center !important;
  }
  
  .list-style-disc {
    list-style-type: disc !important;
  }
  
  .list-style-circle {
    list-style-type: circle !important;
  }
  
  .list-style-auto {
    list-style-type: auto !important;
  }
  
  .pointer-none {
    pointer-events: none !important;
  }
  
  .wz-border {
    border: 0.8px solid $input-border !important;
  }
  
  .wz-border-secondary {
    border: 0.8px solid $secondary !important;
  }
  
  .wz-border-left {
    border-left: 0.8px solid $input-border !important;
  }
  
  .wz-border-right {
    border-right: 0.8px solid $input-border !important;
  }
  
  .wz-border-bottom {
    border-bottom: 0.8px solid $input-border !important;
  }
  
  .wz-border-top {
    border-top: 0.8px solid $input-border !important;
  }
  
  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .wz-box-shadow {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .wz-box-shadow-light {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  }
  
  .whitespace-nowrap {
    white-space: nowrap !important;
  }
  
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  
  .text-overflow-ellipsis {
    min-width: auto;
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .d-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .d-between {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  
  .white-img {
    filter: grayscale(100%) brightness(10000%);
  }
  
  .no-transition {
    transition: none !important;
  }

  //font size

  .small-font{
    font-size: 12px;
  }