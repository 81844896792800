@import '../../../styles/variables.scss';


.culture-cont {
    width: 90%;
}

.cult-img {
    min-width:210px !important;
    height: 130px;
}

#childCult{
    object-fit: cover;
    border-radius: 20px;
}

.culture-slide-cont {
    width: 90%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.center-img {
    transform: scale(1.2);
}

#cultureContainer {
    min-height: 200px !important;
}

@media (min-width:1550px){
    .culture-cont{
        width: 70%;
    }

    #cultureContainer {
        min-height: 250px !important;
    }
}

@media (min-width:1880px){
    .cult-img {
        height: 140px !important;
        min-width:205px !important;
    }
}

@media (min-width:1856px) and (max-width:1880px) {

    .cult-img {
        height: 190px !important;
        min-width:355px !important;
    }
}

@media (min-width:1800px) and (max-width:1856px) {

    .cult-img {
        height: 190px !important;
        min-width:350px !important;
    }
}

@media (min-width:1736px) and (max-width:1800px) {

    .cult-img {
        height: 190px !important;
        min-width:338px !important;
    }
}

@media (min-width:1668px) and (max-width:1736px) {

    .cult-img {
        height: 190px !important;
        min-width:325px !important;
    }
}

@media (min-width:1620px) and (max-width:1668px) {

    .cult-img {
        height: 190px !important;
        min-width:310px !important;
    }
}

@media (min-width:1594px) and (max-width:1620px) {

    .cult-img {
        height: 190px !important;
        min-width:300px !important;
    }
}


@media (min-width:1572px) and (max-width:1594px) {

    .cult-img {
        height: 190px !important;
        min-width:295px !important;
    }
}

@media (min-width:1550px) and (max-width:1572px) {
    

    .cult-img {
        height: 190px !important;
        min-width:290px !important;
    }
}

@media (min-width:1544px) and (max-width:1550px) {
    .cult-img {
        height: 125px !important;
        min-width:211px !important;
    }
}

@media (max-width:1496px) {
    .cult-img {
        height: 125px !important;
        min-width:202.5px !important;
    }
}

@media (max-width:1465px) {
    .cult-img {
        height: 125px !important;
        min-width:197.5px !important;
    }
}

// @media (max-width:1440px) {
//     .cult-img {
//         height: 125px !important;
//         min-width:213.5px !important;
//     }
// }

@media (max-width:1418px) {
    .cult-img {
        height: 125px !important;
        min-width:189.5px !important;
    }
}

// @media (max-width:1398px) {
//     .cult-img {
//         height: 125px !important;
//         min-width:207.5px !important;
//     }
// }

// @media (max-width:1380px) {
//     .cult-img {
//         height: 125px !important;
//         min-width:204.5px !important;
//     }
// }

@media (max-width:1368px) {
    .cult-img {
        height: 120px !important;
        min-width:181.5px !important;
    }
}

// @media (max-width:1342px) {
//     .cult-img {
//         height: 125px !important;
//         min-width:197.5px !important;
//     }
// }

@media (max-width:1319px) {
    .cult-img {
        height: 115px !important;
        min-width:175.5px !important;
    }
}

// @media (max-width:1303px) {
//     .cult-img {
//         height: 125px !important;
//         min-width:191.5px !important;
//     }
// }

@media (max-width:1284px) {
    .cult-img {
        height: 115px !important;
        min-width:168.1px !important;
    }
}

@media (max-width:1236px) {
    .cult-img {
        height: 100px !important;
        min-width:160.2px !important;
    }
}

@media (max-width:1190px) {
    .cult-img {
        height: 140px !important;
        min-width:281px !important;
    }
}


@media (max-width:1140px) {
    .cult-img {
        height: 140px !important;
        min-width:268px !important;
    }
}


@media (max-width:1094px) {
    .cult-img {
        height: 140px !important;
        min-width:254px !important;
    }
}

@media (max-width:1040px) {
    .cult-img {
        height: 140px !important;
        min-width:241px !important;
    }
}

@media (max-width:992px) {
    .cult-img {
        height: 130px !important;
        min-width:228px !important;
    }
}

@media (max-width:947px) {
    .cult-img {
        height: 130px !important;
        min-width:214.5px !important;
    }
}

@media (max-width:895px) {
    .cult-img {
        height: 120px !important;
        min-width:199.5px !important;
    }
}

@media (max-width:837px) {
    .cult-img {
        height: 120px !important;
        min-width:185.5px !important;
    }
}

@media (max-width:785px) {
    .cult-img {
        height: 110px !important;
        min-width:171.5px !important;
    }
}


@media (max-width:738px) {
    .cult-img {
        height: 280px !important;
        min-width:556px !important;
    }

    #cultureContainer {
        min-height: 200px !important;
    }

    .center-img {
        transform: scale(1);
    }
}


@media (max-width:690px) {
    .cult-img {
        height: 280px !important;
        min-width:518px !important;
    }
}

@media (max-width:642px) {
    .cult-img {
        height: 270px !important;
        min-width:478px !important;
    }
}

@media (max-width:619px) {
    .cult-img {
        height: 270px !important;
        min-width:449px !important;
    }
}


@media (max-width:579px) {
    .cult-img {
        height: 250px !important;
        min-width:412.5px !important;
    }
}

@media (max-width:538px) {
    .cult-img {
        height: 230px !important;
        min-width:378.5px !important;
    }
}

@media (max-width:494px) {
    .cult-img {
        height: 240px !important;
        min-width:340.5px !important;
    }
}

@media (max-width:448px) {
    .cult-img {
        height: 200px !important;
        min-width:300px !important;
    }
}

@media (max-width:400px) {
    .cult-img {
        height: 160px !important;
        min-width:264px !important;
    }
}

@media (max-width:360px) {
    .cult-img {
        height: 150px !important;
        min-width:250px !important;
    }
}



@media(min-width:2500px) {
    .culture-cont{
        width: 45%;
    }
}

@media(min-width:3500px) {
    .culture-cont{
        width: 40%;
    }
}

@media(min-width:4500px) {
    .culture-cont{
        width: 30%;
    }
}

@media(min-width:5000px) {
    .culture-cont{
        width: 25%;
    }
}


