@import '../../../../styles/variables.scss';

.secure-hub-cont {
    width: 100%;
    padding: 0 5%;
    position: sticky;
    top: 0%;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        visibility: hidden;
    }
    transition: all 0.3s;
}

#parentCont {
    height: fit-content;
    overflow: visible;
    position: sticky;
    top: 0px;
}

.title-cont {
    width: 50%;
    margin-left: -33px !important;
    font-size: 18px;
}

.role-cont {
    background-color: rgba(#EEEBF6, 0.5);
    width: 100%;
    padding: 15px 20px !important;
}

.role-child-cont {
    width: 30%;
}

.hr-hub-list {
    list-style-type: none;
}

.border-left {
    border-left: 2px dotted rgba(#000000, 0.2);
    margin-left: 40px;
}

.role-op {
    opacity: 0.9;
}

.bg-light-green {
    background-color: #EBF6EB;

    > *:first-child {
        fill: none;
    }

    > *:last-child {
        stroke: #57B757;
    }

}

.hr-feature-cont {
    width: 100%;
}

.inner-hr-feature-cont {
    width: 50%;
}

.hr-feature-img {
    width: 44%;
    border-radius: 10px;
    background-image: radial-gradient(circle #add9ad, #add9ad);
    box-shadow: 0 0 10px 1px #add9ad;
    margin: 0 20px 0 8px;
}

.p3-size {
    font-size: 14px;
}

.move-arrow {
    margin-left: 24px;
}

.cont {
    position: sticky !important;
    transition: all 0.3s ease-out;
    background: #fff;
    // margin-bottom: 100px !important;
    padding-bottom: 100px !important;
}

.h3-size {
    font-size: 20px;
}

@media(min-width:1550px){
    .cont {
        padding-bottom: 50px !important;
        top: 80px !important;
    }

    .secure-hub-cont {
        padding: 0 15%;
    }
}

@media(min-width:2500px) {
    .secure-hub-cont {
        padding: 0 27.5% !important;
    }
}

@media(min-width:3500px) {
    .secure-hub-cont {
        padding: 0 30% !important;
    }
}

@media(min-width:4500px) {
    .secure-hub-cont {
        padding: 0 35% !important;
    }
}

@media(min-width:5000px) {
    .secure-hub-cont {
        padding: 0 37.5% !important;
    }
}



@media(max-width:1275px){
    .hr-feature-cont {
        flex-direction: column !important;
        align-items: start !important;
    }

    .inner-hr-feature-cont {
        width: 100%;
    }

    .hr-feature-img {
        width: 70%;
    }

    .cont{
        top: -300px !important;
    }
}


@media(max-width:992px) {
    .title-cont {
        width: 60%;
    }
}

@media(max-width:768px) {
    .role-cont {
        padding: 20px 20px 0 20px;
        flex-direction: column !important;
        justify-content: start !important;
    }

    .role-child-cont {
        width: 100%;
        margin-bottom: 20px;
    }

    .h3-size {
        font-size: 14px;
    }

    .cont{
        top: -450px !important;
    }
}


@media(max-width:576px) {
    .title-cont {
        width: 70%;
        font-size: 14px;
    }

    .p3-size {
        font-size: 12px;
        margin-bottom: 5px !important;
        margin-top: 0 !important;
    }

    .hr-feature-img {
        width: 90%;
    }
}


@media(max-width:450px) {
    .title-cont {
        width: 90%;
        font-size: 14px;
        margin-left: -31px !important;
    }

    .border-left {
        margin-left: 20px;
    }

    .move-arrow {
        margin-left: 4px;
    }

    .cont{
        top: -420px !important;
    }

    .role-child-cont {
        width: 100%;
    }
}

@media (max-width:390px){
    .cont{
        top: -520px !important;
    }
    .hr-feature-img{
        width: 96%;
    }
}

