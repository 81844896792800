@import '../../../../styles/variables.scss';

.geo-img{
    width: 100%;
    z-index: 1;
}

.geo-bgnd {
    width: 350px;
    height: 300px;
    background-image: radial-gradient(circle, #FFCB94, #FFCB94);
    background-size: cover;
    box-shadow: 0 0 40px 45px #FFCB94;
    opacity: 0.3;
}

#hr_g{
    path{
        fill: #FF5722 !important;
    }
}

.geo-top{
    top: 45px;
}
.geo-bottom{
    bottom: 85px;
}


@media(max-width:780px){
    .geo-bgnd{
        width: 270px;
        height: 180px;
    }
}

@media(max-width:580px){
    .geo-bgnd{
        width: 250px;
        height: 160px;
    }
}

@media(max-width:490px){
    .geo-bgnd{
        width: 220px;
        height: 110px;
    }
}

@media(max-width:390px){
    .geo-bgnd{
        width: 180px;
        height: 90px;
    }
    .geo-top{
        top: -36px;
    }
    .geo-bottom{
        bottom: 2px;
    }
}