@import"../../../styles/variables.scss";

.mob-hr-products{
    padding: 50px 10px 0 10px;
    margin-top: 60px;
    position: absolute; 
    z-index: 20;
    background-color: #fff;
    overflow-y: scroll;
    height: 100vh;
}

.mob-hr-prod-ul{
    padding: 0 30px 30px 30px;
    margin-bottom: 120px;
}

.mob-hr-prod-list{
    width: 100%;
    margin: 20px 0 20px 0px;
    font-family: #{$lexend-deca};
    font-size: 13px;
    font-weight: 500;
    color: #29282899;
    cursor: pointer;
}

.mob-hr-link{
    text-decoration: none;
    display: none;
}


@media (max-width:1010px){

    .mob-hr-link{
        display: block;
    }
}

@media (max-width:690px){

    .mob-hr-products{
        padding: 0 10px 0 10px;
    }
    
    .mob-hr-prod-ul{
        padding: 0px 30px 30px 30px;
        margin-bottom: 120px;
    }
}

@media (max-width:390px){

    .mob-hr-products{
        padding: 0 10px 0 10px;
        margin-top: 60px;
    }
}
