@import '../../../styles/variables.scss';


.story-cont {
    padding: 50px 0;
}

.our-story-img {
    width: 50%;
}

.our-story-cont {
    width: 45%;
}

@media (max-width:992px) {
    .story-cont {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .our-story-img {
        width: 100%;
        order:1;
    }

    .our-story-cont {
        width: 100%;
        order:0;
    }
}