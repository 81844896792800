.slide-size2 {
    flex-wrap: nowrap;
    overflow: hidden;
    height:90px;
}

@media (max-width:768px){
    .slide-size2 {
        width: 98% !important;
    }
}

