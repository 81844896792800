@font-face {
    font-family: 'Lexend-Deca';
    font-weight: 350;
    src: url('../assets/fonts/LexendDeca-VariableFont_wght.ttf') format('truetype');
        // url('../assets/fonts/LexendDeca-Black.ttf') format('truetype'),
        //  url('../assets/fonts/LexendDeca-Bold.ttf') format('truetype'),
        //  url('../assets/fonts/LexendDeca-ExtraBold.ttf') format('truetype'),
        //  url('../assets/fonts/LexendDeca-ExtraLight.ttf') format('truetype'),
        //  url('../assets/fonts/LexendDeca-Light.ttf') format('truetype'),
        //  url('../assets/fonts/LexendDeca-Medium.ttf') format('truetype'),
        //  url('../assets/fonts/LexendDeca-Regular.ttf') format('truetype'),
        //  url('../assets/fonts/LexendDeca-SemiBold.ttf') format('truetype'),
        //  url('../assets/fonts/LexendDeca-Thin.ttf') format('truetype'),
  }


@font-face {
    font-family: 'Sora';
    src: url('../assets/fonts/Sora-SemiBold.ttf') format('truetype'),
        // url('../assets/fonts/Sora-Bold.ttf') format('truetype'),
        // url('../assets/fonts/Sora-ExtraBold.ttf') format('truetype'),
        // url('../assets/fonts/Sora-ExtraLight.ttf') format('truetype'),
        // url('../assets/fonts/Sora-Light.ttf') format('truetype'),
        // url('../assets/fonts/Sora-Medium.ttf') format('truetype'),
        // url('../assets/fonts/Sora-Regular.ttf') format('truetype'),
        // url('../assets/fonts/Sora-Thin.ttf') format('truetype'),
        // url('../assets/fonts/Sora-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Sora-Light';
    src: url('../assets/fonts/Sora-Regular.ttf') format('truetype');
}