@import '../../../../styles/variables.scss';

.pro-merits{
    width: 100%;
    background-image: linear-gradient(to right , #ecffec, #f8f8f8);
}

.pro-center{
    width: 90%;
    margin: 0;
    padding: 15px 0 10px 0;
}

.merits-cont{
    width: 28%;
    color: #000000;
    padding: 10px;
}

.merit-head{
    font-family: #{$sora};
}

.merit-para{
    font-family: #{$lexend-deca};
}

.green-line{
    margin-right: 10px;
    margin-top: -5px;
}

@media(min-width:1550px){
    .pro-center{
        width: 70% !important;
    }
}

@media(min-width:2500px) {
    .pro-center{
        width: 45% !important;
    }
}

@media(min-width:3500px) {
    .pro-center{
        width: 40% !important;
    }
}

@media(min-width:4500px) {
    .pro-center{
        width: 30% !important;
    }
}

@media(min-width:5000px) {
    .pro-center{
        width: 25% !important;
    }
}


@media(max-width:1275px){
    .merits-cont{
       width: 31%;
    }
}


@media(max-width:992px){
    .pro-center {
        flex-direction: column !important;
    }
    .merits-cont {
        width: 90%;
    }
}
