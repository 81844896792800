.testimonial-form-container{
    width: 60%;
    border-radius: 10px;
    background-image: radial-gradient(circle #FF57223A, #FF57223A);
    box-shadow: 0 0 10px 1px #FF57223A;
    margin-top: -150px;
    z-index: 1;
    background-color: #ffffff;
    padding: 20px 40px;
}
.testimonial-textarea{
    margin-top: 10px 0;
    // background-color: aqua;
}
.response-container{
    width: 60%;
    border-radius: 10px;
    background-image: radial-gradient(circle #FF57223A, #FF57223A);
    box-shadow: 0 0 10px 1px #FF57223A;
    margin-top: -150px;
    margin-bottom: 20px;
    z-index: 1;
    background-color: #ffffff;
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    margin-top: 100px;
}
@media (max-width:885px) {
    .testimonial-form-container{
        width: 70%;
        
    }
    .response-container{
        width: 70%;
       
    }
}
@media (max-width:540px) {
    .testimonial-form-container{
        width: 85%;
        
    }
    .response-container{
        width: 85%;
       
    }
}