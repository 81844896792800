@import "../../../styles/variables.scss";

.marquee-container {
    overflow: hidden;
    width: 100%;
}

.marquee-text {
    white-space: nowrap;
    animation: marquee 10s linear infinite;
}

.border-top{
  border-top: 1px solid grey;
  padding: 15px 0 5px 0;
}

.prod-link{
  color:#000000;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  font-family: #{$lexend-deca};
}

.mob-prod-list{
  color:#000000;
  list-style: none;
  font-size: 13px;
  font-weight: 500;
  font-family: #{$lexend-deca};
  padding-left: 10px;
}

.mob-products{
  padding: 7px 0 7px 12px;
  margin:0;
}

@keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-125%); }
}

.blog-popup {
    box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.1);
    width:280px;
    padding: 20px;
    z-index: 4;
  }

  .blog-mob{
    border-top: 1px solid grey;
    padding-top: 5px;
  }

@media (min-width:1130px){
    .blog-popup{
      display: none !important;
    }
  }
  
@media (max-width:576px){
    .blog-popup{
        width: 280px;
    }
}