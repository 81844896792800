@import '../../../styles/variables.scss';


.border-btm {
    border-bottom: 1px solid #D0D0D0;
    margin: 0 0 15px 0;
}

.light-blue{
    background-color: rgba(246, 249, 252, 0.4);
    pointer-events: none;
}

.p2-size{
    font-size: 16px;
}
.margin-padding-resrict{
    margin: 0 !important;
    padding: 0 !important;
}

// svg[stroke]{
//     color:#F45600 !important;
// }

@media (max-width:500px){
    .p2-size{
        font-size: 14px;
    }
}