@import '../../../styles/variables.scss';

.quote-excellence{
    width: 100%;
    padding: 0 0 120px 0;
    margin-bottom: 20px;
}
.pricequote{
     padding-left: 200px;
     padding-right: 200px;

}
.combined-word {
    white-space: nowrap;
}
.mob-width-price{
    width:30%;
}
.popper{
    position: absolute;
    right: 35%;
    top: -75px;

}
.product-inner-pricing-cont{
    width: 90%;
    margin-top: 50px;
}

.step-into-pricing-card{
    // width: 45%;
    width:580px;
    height: 350px;
}
.offer-img{
    transform: rotate(20deg);
    position: absolute;
    top: -110px; 
    // right: 23%;
    right:-50px;
    height: auto;
   
}
.second-pricing-s{
    width: 50%;
    margin: 20px 0 0 20px;
}
.right-logo-w{
   right:-45px;
   top: 100px; 
   transform: rotate(65deg);
}
.left-logo-w{
    top:180px;
    left:-180px;
    transform: rotate(65deg);
}

#Leftw{
    path {
        fill: #72d572 !important;
    }
}

#Rightw{
    path{
        fill: #72d572 !important;
    }
    z-index: -1;
}
.offImg{
    width: 230px;
    height: 230px;
    object-fit: contain;
    transform: rotate(-19deg);
  
}
.hide-price{
    display: none !important;
}
.product-pricing-heading{
    font-family: #{$sora};
}

.bgnd-prod {
    width: 380px;
    height: 300px;
    background-image: radial-gradient(circle, #add9ad, #add9ad);
    background-size: cover;
    box-shadow: 0 0 50px 40px #add9ad;
    opacity: 0.3;
}

.custom-shape-divider-bottom-1715923248 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1715923248 svg {
    position: relative;
    display: block;
    width: calc(152% + 1.3px);
    height: 81px;
}

.custom-shape-divider-bottom-1715923248 .shape-fill {
    fill: #FFFFFF;
}
.big-screen-price{
    display: block;
}


.hr-top-pr{
    top: 45px;
    // z-index: -1;
}
.hr-bottom-pr{
    bottom: 85px;
    
}
.child2-right{
    width: 300px;
}
@media(min-width:1550px){
    .product-inner-pricing-cont{
        width: 70%;
    }
    .mob-width-price{
        width:35%;
    }
}


@media(min-width:2500px) {
    .product-inner-pricing-cont{
        width: 45% !important;
    }

    // .hr-product-slide {
    //     width: 45%; 
    //     align-self: center !important;
    // }
}

@media(min-width:3500px) {
    .product-inner-pricing-cont{
        width: 40% !important;
    }

    // .hr-product-slide {
    //     width: 40% !important; 
    // }
}

@media(min-width:4500px) {
    .product-inner-pricing-cont{
        width: 30% !important;
    }

    // .hr-product-slide {
    //     width: 30% !important; 
    // }
}

@media(min-width:5000px) {
    .product-inner-pricing-cont{
        width: 25% !important;
    }

    // .hr-product-slide {
    //     width: 25% !important; 
    // }
   
}



@media(max-width:1257px){
    .product-inner-pricing-cont{
        flex-direction: column !important;
        align-items: center !important;
    }
    .step-into-pricing-card{
        width: 100%;
        align-items: center !important;
        padding-bottom: 60px;
        text-align: center;
    }
    .second-pricing-s{
        width: 90%;
        margin: 0;
    }
    .popper{
        position: absolute;
        right: -50%;
        top: -80px;
    
    }
    .mob-width-price{
        width:35%;
    }
}
@media(max-width:1550px){
  
    .pricequote{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }   
}
@media(max-width:860px){
   
    // .step-into-pricing-card{
    //   margin-top: 50px; 
    // }
    
    .mob-width-price{
        width:35%;
        margin-left: 10px;
    }
}

@media(max-width:780px){
   
    .step-into-pricing-card{
        width: 500px !important;
        align-items: center !important;
        padding-bottom: 60px;
        text-align: center;
    }
    .bgnd-prod {
        width: 355px;
        height: 250px;
    }

    #sub1{
        left: -25px;
    }

    #sub2{
        bottom: -90px;
        right: -20px;
    }

    #sub3{
        top: -35px;
        right: -15px;
    }
    .offer-img{
        top: -110px; 
        right:-80px !important;
    }
}

@media(max-width:580px){
   
    .bgnd-prod {
        width: 280px;
        height: 180px;
    }
    // .offer-img{
    //     right: -7% !important;
    //     top:-70px !important;
    // }
    .big-screen-price{
        display: none !important;
    }
    .hide-price{
     
        width: 100% !important;
        display: block !important;
    }
    .offer-text{
        font-size: 10px !important;
    }
    .step-into-pricing-card{
        width: 400px !important;
        
    }
    .offer-img{
        top: -110px; 
        right:-100px !important;
    }
    .offImg{
        width: 200px !important;
    }
    .popper{
        position: absolute;
        right:-50% !important;
        top: -90px;
    
    }
}

@media(max-width:490px){
   
    .bgnd-prod {
        width: 260px;
        height: 160px;
    }
    .second-pricing-s{
        width: 100%;
    }
    .step-into-pricing-card{
        width: 300px !important;
        
    }
    .offer-img{
        top: -100px; 
        right:-100px !important;
    }
    .offImg{
        width: 180px !important;
    }
    .combined-word {
        white-space: nowrap;
    }
}
@media(max-width:420px){
   

    .step-into-pricing-card{
        width: 280px !important;
        
    }
    .offer-img{
        top: -100px; 
        right:-90px !important;
    }
    .offImg{
        width: 160px !important;
    }
}

@media(max-width:390px){
   
    .bgnd-prod {
        width: 230px;
        height: 130px;
    }

    #sub1{
        left: -20px;
    }

    #sub2{
        bottom: -80px;
        right: -20px;
    }

    #sub3{
        top: -25px;
        right: -15px;
    }
    .hr-top-pr{
        top: 40px;
        z-index: 1;
    }
    .hr-bottom-pr{
        bottom: 85px;
        z-index: 1;
    }
  
    .offer-img{
        top: -90px; 
        right:-55px !important;
    }
    .offImg{
        width: 120px !important;
    }
  
}