@import '../../../styles/variables.scss';


.bg-image {
    background-color: rgba(190, 223, 255, 0.3);
    border:0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}

.font-clr {
    color:#{$color3}
}

.attendance-card {
    width: 32%;
    height:150px;
    overflow: hidden;
}

.attendence-img{
    top: 90px;
}

@media (max-width:1275px) {
    .attendance-card {
        width: 100%;
    }

    .attendence-img{
        top: 85px;
    }
}


@media (max-width:768px) {
    .attendence-img{
        top: 90px;
    }
}

@media (max-width:450px) {
    .attendence-img{
        top: 100px;
    }
}