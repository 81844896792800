@import '../../../Styles/variables.scss';


.leave-cont {
    width: 90%;
}

.op1 {
    opacity: 0.7;
}

.prog1 {
    background-color: #D9D9D9;
    width: 100%;
    height:3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    
}


.prog {
    height:3px;
    width: 100%;
    align-self:flex-start;
}

.p-big-size {
    font-size: 16px;
}

.options {
    width: 90%;
}

.mobile-design{
    display: none;
}

.hide-in-mob {
    display: inline;
}

@media (max-width:1275px) {
    .card-cont {
        flex-direction: column !important;
        justify-content: center !important;
    }

    .options {
        width: 90%;
    }

    .mobile-design {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
    }

}

@media (max-width:840px) {

    .hide-in-mob {
        display: none;
    }
}

@media (max-width:500px) {
    .p-big-size {
        font-size: 12px;
    }

    .options{
        width: 100%;
    }
}

@media (min-width:1550px){
    .leave-cont {
        width: 70%;
    }
}


@media(min-width:2500px) {
    .leave-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .leave-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .leave-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .leave-cont {
        width: 25%;
    }
}