@import '../../../../styles/variables.scss';

#a-line{
    rect{
        fill: #12A8A8;
    }
}

.attend-merits{
    background-image: linear-gradient(to right, #12A8A81A, #64c4c41a);
    width: 100%;
}
