@import '../../../styles/variables.scss';

.form-bg-outer{
    width: 90%;
border-radius: 10px;
background-image: radial-gradient(circle #FF57223A, #FF57223A);
box-shadow: 0 0 10px 1px #FF57223A;
margin-top: -150px;
z-index: 1;
background-color: #ffffff;
}
.goodbye-text{
    color: rgba(#3c3c3c,  0.6);
}
.quote-left-container{
    width: 50%;
}
.quote-right-container{
    // width: 80%;
}
.quote-container{
    display: flex;
    flex-direction: row;
    justify-content: around;
}
.price-checkbox{
    
   font-family:'Sora', sans-serif !important;
   font-weight: 400 !important;
}
.quote-bg-res{
    height:30vh;
    width: 100%;
    border-radius: 10px;
}
@media(max-width:860px){
    .quote-container{
         flex-direction: column;
         justify-content: center;
         align-items: center;
     
        
    }
    .quote-right-container{
        width: 100% !important;
    }
    .quote-text-size{
        font-size: 23px !important;
    }

   .quote-left-container{
    width: 100%;
}
}
