@import "./variables.scss";

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall{
    height: 30px !important;
    background-color: #FFFFFF !important;
    border-radius: 8px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall {
    border-radius: 8px !important;
    font-size: 14px !important;
    font-family: #{$lexend-deca} !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
    margin-top: 5px;
    font-size: 14px !important;
    font-family: #{$lexend-deca} !important;
}

.Mui-focused {
    margin-top: 0px !important;
    color: var(--wz-primary) !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--wz-primary) !important;
    border-width: 1px !important;
}

.react-tel-input {
    display: flex;
    flex-direction: row;
    justify-content: center
}

.react-tel-input .flag-dropdown{
    border: none !important;
    background-color: transparent !important;
    // display: none;
    margin-left: 15px !important;
    left:0;
    top:10px !important;
    margin-right: 20px !important;
    height: 30px !important;
}

.react-tel-input .special-label {
    display: none !important;
}

.react-tel-input .form-control {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    background-color: #FFFFFF !important;
    outline:none !important;
    width:95% !important;
    height:45px !important;
    border-radius: 8px !important;
    // margin-top: 10px !important;
    // margin-bottom: 0px !important;
    padding-left: 55px !important;
    font-size: 14px !important;
    font-family: #{$lexend-deca} !important;

}

.form-control {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}


.form-control:hover{
    border-color: rgba(0, 0, 0, 0.83) !important;
}

.form-control:focus {
    box-shadow: none !important;
    border-color:var(--wz-primary) !important;
}

.form-control:not(:focus) {
    border-color:#D32F2F; 
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: #FFFFFF !important;
}

.phone-input-error .form-control {
    border-color: #D32F2F !important;
}

.focused .phone-input-error .form-control {
    border-color: var(--wz-primary) !important;
}

.phone-input-container {
  position: relative;
  margin-bottom: -5px;
}

.phone-input-container .form-control {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 18.5px 14px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
}

.phone-input-container .form-control:focus {
  border-color: var(--wz-primary);
  box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.25);
}

.phone-input-container.error .form-control {
  border-color: #d32f2f;
}

.phone-input-container label {
  position: absolute;
  top: 34%;
  left: 22px;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.54);
  background-color: white;
  padding: 0 4px;
  transition: 0.2s;
  pointer-events: none;
  z-index:1 !important;
  font-size: 14px;
  font-family: #{$lexend-deca} !important;
}

.phone-input-container.focused label,
.phone-input-container.filled label {
  top: -8px;
  left: 18px;
  transform: translateY(0);
 // background-image: linear-gradient(to bottom, #FEF4EA, #ffffff);
  font-size: 11px;
  color: var(--wz-primary);
  font-family: #{$lexend-deca} !important;
}

.phone-input-container.error label {
  color: #d32f2f;
  top: 35%;
}

.phone-input-container.focused.error label {
    color: #00000099;
    top: -8px;
}

.phone-input-container.filled label {
    color: #00000099;
}

.phone-input-container.filled.error label {
    color: #d32f2f;
    top: -8px;
}

.has-value {
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
        margin-top: 0;
    }
}

.has-error-value {
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
        margin-top: 0;
    }
}

.MuiAutocomplete-endAdornment{
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator:hover {
        background-color: transparent !important;
    }
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
    font-size: 12px !important;
    font-family: #{$lexend-deca} !important;
}

.MuiAutocomplete-listbox {
    .MuiAutocomplete-option {
        font-size: 12px !important;
        font-family: #{$lexend-deca} !important;
    }
}


.MuiAutocomplete-listbox {
    .MuiAutocomplete-option[aria-selected=true] {
        color: var(--wz-secondary) !important;
        background-color: rgba(25, 118, 210, 0.08) !important;
    }
    
}


.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters{
    color: var(--wz-secondary) !important;
}


.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall{
    margin-top: 4px !important;
    padding: 8.5px 14px 4px 14px !important;
}