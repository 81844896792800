@import '../../../styles/variables.scss';


.our-team-cont {
    width: 90%;
}


@media (min-width:1550px){
    .our-team-cont {
        width: 70%;
    }
}

@media(min-width:2500px) {
    .our-team-cont {
        width: 45%;
    }
}

@media(min-width:3500px) {
    .our-team-cont {
        width: 40%;
    }
}

@media(min-width:4500px) {
    .our-team-cont {
        width: 30%;
    }
}

@media(min-width:5000px) {
    .our-team-cont {
        width: 25%;
    }
}