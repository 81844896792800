@import '../../../../styles/variables.scss';

#Leftw-e{
    path {
        fill: rgba(#227AFF, 1)!important;
    }
}

#ess-top{
    path {
        fill: rgba(#227AFF, 1)!important;
    }
}
#ess-bottom{
    path {
        fill: rgba(#227AFF, 1)!important;
    }
}

.ess-top{
    top: 30px;
    // z-index: -1;
}
.ess-bottom{
    bottom: 70px;
    // z-index: -1;
}
.ess-img{
    width: 60%;
    z-index: 1;
}

.bgnd-ess {
    width: 250px;
    height: 100px;
    background-image: radial-gradient(circle, #227AFF4A, #227AFF4A);
    background-size: cover;
    box-shadow: 0 0 50px 40px #227AFF4A;
    opacity: 0.4;
}

@media(max-width:780px){
    .bgnd-ess {
        width: 355px;
        height: 200px;
    }
}

@media(max-width:580px){
    .bgnd-ess {
        width: 280px;
        height: 120px;
    }
    .ess-top{
        top: -9px;
        z-index: -1;
    }
    .ess-bottom{
        bottom: 31px;
        z-index: -1;
    }
}

@media(max-width:490px){
    .bgnd-ess {
        width: 260px;
        height: 100px;
    }

    .ess-img{
        width: 60%;
    }
}

@media(max-width:390px){
    .bgnd-ess {
        width: 230px;
        height: 130px;
    }
    .ess-top{
        top: -51px;
        z-index: -1;
    }
    .ess-bottom{
        bottom: -11px;
        z-index: -1;
    }
}