// .apexcharts-bar-series .apexcharts-bar {
//     margin-right: 5px;
// }

// .bar-chart {
//   width: 304px;
//   height: 148px;
// }

// @media (max-width:576px) {
//   .bar-chart {
//     width: 250px !important;
//   }
// }

// @media (max-width:440px) {
//   .bar-chart {
//     width: 190px !important;
//     height: 130px !important
//   }

//   .move{
//     position: absolute;
//     bottom: -30px;
//     left:-5px;
//     width: 120%;
//   }
// }


.apexcharts-bar-series .apexcharts-bar {
  margin-right: 5px;
}

.bar-chart {
width: 304px;
height: 148px;
}

@media (max-width:576px) {
.bar-chart {
  width: 250px !important;
}
}

@media (max-width:440px) {
.bar-chart {
  width: 190px !important;
  height: 135px !important
}

.move{
  position: absolute;
  top: 22px;
  left:-10px;
}
}