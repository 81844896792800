@import '../../../../styles/variables.scss';

.hr-excellence{
    width: 100%;
    padding: 0 0 120px 0;
    margin-bottom: 20px;
}

.product-inner-cont{
    width: 90%;
    margin-top: 80px;
}

.step-into-card{
    width: 45%;
}

.second-s{
    width: 50%;
    margin: 20px 0 0 20px;
}

#Leftw{
    path {
        fill: #72d572 !important;
    }
}

#Rightw{
    path{
        fill: #72d572 !important;
    }
    z-index: -1;
}

.product-img{
    width: 100% ;
    z-index: 3;
}

.product-heading{
    font-family: #{$sora};
}

.bgnd-prod {
    width: 380px;
    height: 300px;
    background-image: radial-gradient(circle, #add9ad, #add9ad);
    background-size: cover;
    box-shadow: 0 0 50px 40px #add9ad;
    opacity: 0.3;
}

.custom-shape-divider-bottom-1715923248 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1715923248 svg {
    position: relative;
    display: block;
    width: calc(152% + 1.3px);
    height: 81px;
}

.custom-shape-divider-bottom-1715923248 .shape-fill {
    fill: #FFFFFF;
}

.hr-product-slide {
    width: 100%;
}

.hr-top{
    top: 45px;
    // z-index: -1;
}
.hr-bottom{
    bottom: 85px;
    // z-index: -1;
}

@media(min-width:1550px){
    .product-inner-cont{
        width: 70%;
    }
}


@media(min-width:2500px) {
    .product-inner-cont{
        width: 45% !important;
    }

    .hr-product-slide {
        width: 45%; 
        align-self: center !important;
    }
}

@media(min-width:3500px) {
    .product-inner-cont{
        width: 40% !important;
    }

    .hr-product-slide {
        width: 40% !important; 
    }
}

@media(min-width:4500px) {
    .product-inner-cont{
        width: 30% !important;
    }

    .hr-product-slide {
        width: 30% !important; 
    }
}

@media(min-width:5000px) {
    .product-inner-cont{
        width: 25% !important;
    }

    .hr-product-slide {
        width: 25% !important; 
    }
}


@media(max-width:1257px){
    .product-inner-cont{
        flex-direction: column !important;
        align-items: center !important;
        // height: 650px !important;
    }
    .step-into-card{
        width: 100%;
        align-items: center !important;
        padding-bottom: 60px;
        text-align: center;
    }
    .second-s{
        width: 90%;
        margin: 0;
    }
}

@media(max-width:780px){
   
    .step-into-card{
        width: 100%;
        align-items: center !important;
        padding-bottom: 60px;
        text-align: center;
    }
    .bgnd-prod {
        width: 355px;
        height: 250px;
    }

    #sub1{
        left: -25px;
    }

    #sub2{
        bottom: -90px;
        right: -20px;
    }

    #sub3{
        top: -35px;
        right: -15px;
    }
}

@media(max-width:580px){
   
    .bgnd-prod {
        width: 280px;
        height: 180px;
    }
}

@media(max-width:490px){
   
    .bgnd-prod {
        width: 260px;
        height: 160px;
    }
    .second-s{
        width: 100%;
    }
}

@media(max-width:390px){
   
    .bgnd-prod {
        width: 230px;
        height: 130px;
    }

    #sub1{
        left: -20px;
    }

    #sub2{
        bottom: -80px;
        right: -20px;
    }

    #sub3{
        top: -25px;
        right: -15px;
    }
    .hr-top{
        top: -38px;
        z-index: -1;
    }
    .hr-bottom{
        bottom: 0;
        z-index: -1;
    }
}