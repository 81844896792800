@import '../../../styles/variables.scss';

.team-view-cont {
    width: 90%;
}

.team-view-img {
    width:45%;
    border-radius: 50px;
}

.view-cont {
    width: 45%;
}

@media (min-width:1550px){
    .team-view-cont{
        width: 70%;
    }
}

@media(min-width:2500px) {
    .team-view-cont{
        width: 45%;
    }
}

@media(min-width:3500px) {
    .team-view-cont{
        width: 40%;
    }
}

@media(min-width:4500px) {
    .team-view-cont{
        width: 30%;
    }
}

@media(min-width:5000px) {
    .team-view-cont{
        width: 25%;
    }
}


@media (max-width:992px){
    .team-view-cont {
        flex-direction: column !important;
        justify-content: start !important;
    }

    .team-view-img {
        width:85%;
        order:1;
    }
    
    .view-cont {
        width: 100%;
        order: 0;
        margin-bottom: 30px;

    }
}