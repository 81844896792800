@import '../../../styles/variables.scss';

.outside-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 80px 0 80px 0;
}

.overall-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    align-self: center;
}

.platform-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 25%;
}

.platform-img {
    width:100%;
}

.slogan2 {
    color:#00B2A9;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 8px;
}

.head {
    font-size: 28px;
    font-weight: 600;
    color: #{$color1};
    margin: 0;
    line-height: 35px;
}

.orange {
    color: #{$color2};
}

.content {
    font-size: 14px;
    font-weight: 300;
    color: #{$color3};
    font-family: #{$lexend-deca};
}

.platform-list-cont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style-type:none;
    padding-left: 0px;
    margin: 0;
}

.platform-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:20px;
    margin-bottom: 18px;
    cursor: pointer;
}

.platform-title {
    font-size: 12px;
    font-weight: 600;
    line-height:15.12px;
    color: #{$color3};
}

.title-content {
    font-size: 10px;
    font-weight: 300;
    line-height:12.6px;
    color: #{$color3};
    font-family: #{$sora-light};
}

.round {
    width:30px;
    height:30px;
    border-radius:20px;
    // border:1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.side-container {
    width:6%;
    position: absolute;
    z-index: -1;
    right: 2.5%;
    top:28%;
}

.curve {
    width: 129px;
}


.border-right {
    border-right: 1px solid rgb(0,0,0, 0.1);
}


.show-mob-feature {
    display: none;
}

.shad {
    box-shadow: 0 0 1px 2px rgb(0,0,0, 0.1);
    border-radius:10px;
}


.mob-platform{
    display: none;
}

@media (max-width:1275px) {
    .overall-container{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .platform-container{
        width: 100%;
        justify-content: center !important;
        align-items: center !important;
    }

    .head{
        text-align: center;
    }

    .content {
        text-align: center;
    }

    .platform-list-cont {
        flex-direction: row !important;
        justify-content: space-between;
        width:100%;
    }

    .platform-list {
        flex-direction: column !important;
        width:14.28%;
        align-items:center !important;
        text-align: center;
    }

    .round {
        margin-top: 20px;
        margin-bottom: -20px;
    }

    .platform-title {
        font-size: 10px;
    }
}

@media (max-width:768px){
    .platform-title {
        font-size: 8px !important;
    }

    .hide-platform-in-mob{
        display: none;
    }

    .mob-platform{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 100%;
    }
}

@media (max-width:520px){
    .hide-feature {
        display: none;
    }
    
    .show-mob-feature {
        display: block;
    }
}


@media (max-width:500px) {
    .head{
      font-size: 22px !important;
      line-height: 30px;
    }
}


@media (min-width:1550px) {
    .overall-container {
        width: 70%;
    }
}


@media(min-width:2500px) {
    .overall-container {
        width: 45%;
    }
  }

  @media(min-width:3500px) {
    .overall-container {
        width: 40%;
    }
  }

  @media(min-width:4500px) {
    .overall-container {
        width: 30%;
    }
  }

  @media(min-width:5000px) {
    .overall-container {
        width: 25%;
    }
  }