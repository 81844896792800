@import '../../../styles/variables.scss';

.subscribe-btn {
    outline: none;
    border: none;
    border-radius: 8px;
    height:45px;
    width: 45%;
    margin-left: 20px;
    margin-top: 5px;
    font-size: 16px;
}

.inbox-email {
    width: 45%;
}

.inbox-card-1 {
    width: 38%;
}

.inbox-card-2 {
    width: 55%;
}

.inbox-form {
    width: 100%;
}

.news {
    right: 0;
}

.inbox-greet-cont {
    width: 50%;
}

@media(max-width:1275px){

    .inbox-card-1{
        width: 100%;

        p{
            text-align: center;
        }

        h2{
            text-align: center;
        }
    }

    .inbox-card-2{
        width: 100%;
    }

    .yellow-card{
        width: 95%;;
    }

    .cent1 {
        text-align: center;
    }

    .news {
        right: -20px;
    }

    .inbox-greet-cont {
        width: 90%;
    }
}

@media (max-width:576px){
    .inbox-form {
        flex-direction: column !important;
        width: 100%;
    }

    .subscribe-btn {
        margin-left: 0px;
        width: 90%;
    }

    .inbox-email {
        width: 90%;
    }

    .inbox-greet-cont {
        width: 100%;
    }
}