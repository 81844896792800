.carousel-container{
    max-width: 550px; /* Adjust width */
  margin: auto;
//   width: auto;
}
// .slide-content {
//     width: 100% !important;
//     border: 1px solid black;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
//   }
  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .slick-track {
    display: flex !important;
  }
  
  .slide-content {
    width: 100% !important;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .slick-slide > div {
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .slick-slider{
    width: 100% !important;
  } 
  .slick-list{
    padding: 0 !important;
    margin: 0 !important;
    height: 430px;
    // width: 100% !important;
    // display: flex !important;
    // width: 600px !important;
    // margin-left: 50px !important;
    // margin-right: -60px !important;
    // display: flex !important;
    // justify-content: center !important;
    // align-items: center !important;
  }
  .custom-indicators {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 8px;
  }
  
  .indicator {
    width: 20px;
    height: 4px;
    background: white;
    border: none; // Dashed border for inactive indicators
    transition: all 0.3s ease;
    border-radius: 3px;
  }
  
  .indicator.active {
    width: 30px; // Increase width for active indicator
    background: #ff7a00; // Fill with orange color
    border: none; // Remove dashed border
  }
  