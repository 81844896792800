@import '../../../../styles/variables.scss';

.attend-modules-cont {
    width: 100%;
    background-image: linear-gradient(to right, #12A8A81A, #64c4c41a);
    max-height: 100px;
}

@media(min-width:2500px) {
    .attend-modules-cont {
        width: 45% !important;
        align-self: center !important;
    }
}

@media(min-width:3500px) {
    .attend-modules-cont {
        width: 40% !important;
    }
}

@media(min-width:4500px) {
    .attend-modules-cont {
        width: 30% !important;
    }
}

@media(min-width:5000px) {
    .attend-modules-cont {
        width: 25% !important;
    }
}


@media (max-width:1275px) {
    .modules-card {
        width: 22%;
    }
}

@media (max-width:1000px) {

    .attend-modules-cont {
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        max-height: 750px;
        margin-top: 20px !important;
        padding-top: 20px;
    }
}

@media (max-width:768px) {
    .attend-modules-cont {
        margin-top: -20px !important;
    }
}