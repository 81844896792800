@import '../../../styles/variables.scss';

.card-1{
    width: 47%;
}

.card-2{
    width: 53%;
}

.yellow-card{
    width: 78%;;
}

.h2-size {
    font-size: 28px;
}

@media(max-width:1275px){

    .footer-card-cont {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .card-1{
        width: 100%;

        p{
            text-align: center;
        }

        h2{
            text-align: center;
        }
    }

    .card-2{
        width: 110%;
    }

    .yellow-card{
        width: 95%;;
    }

    .cent1 {
        text-align: center;
    }
}

@media (max-width:500px) {
    .h2-size{
        font-size: 22px;
    }

}

@media(max-width:390px){

    .yellow-card{
        margin-bottom: -60px !important;
    }
}


@media (min-width:1550px){
    .yellow-card {
        width: 65%;
    }
}