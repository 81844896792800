@import '../../../styles/variables.scss';

.nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:90%;
    padding: 10px 0 10px 0;
    z-index: 5;
}

.nav-main {
    // border-bottom:1px solid rgb(228 228 228);
    z-index:10;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.nav-bar {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #FFFFFF;
    z-index: 5;
}

.contrast-cont {
    width: 100%;
    background-color: #FFFFFF;
}

.link-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:40px;
}

.link {
    color:#000000;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    font-family: #{$lexend-deca};
    position: relative;
}


.link::after{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #{$secondary};
    height: 1px;
    transition: all .3s;
}

.link:hover::after{
    width: 100%;
    transition: all .3s;
}

.get-started-btn{
    height:34px;
    width:110px;
    border-radius:8px;
    // background-color:#F45600;
    color:#ffffff;
    font-size: 14px;
    font-weight: 500;
    border:none;
}

.logo {
    min-width:8%;
    max-width: 11%;
}

.underline {
    border: 0.5px solid #F45600;
}

.switch {
    width: 22px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 50px;
    padding:2px;
    cursor: pointer;
    border:1px solid;
    border-color: rgb(0, 0, 0, 0.2);
}
  
.switch[data-ison="true"] {
    justify-content: flex-end;
}

.handle {
    width: 7px;
    height: 7px;
    border-radius: 20px;
}

.menu {
    display: none;
    color:#F45600;
}

.logo-height {
    height: 40px;
}

.inner-contrast {
    width: 90%;
}

.show-lang{
    display: none !important;
}

  @media (max-width:1010px) {

    .nav-container{
        padding: 0 !important;
    }

    .contrast-cont {
        display: none !important;
    }

    .link-container{
        display: none;
    }

    .menu {
        display: block !important;
        font-size: 24px;
    }

    .logo-height {
        height: 35px;
    }

    .nav-bar {
        position: fixed !important;
        top: 0;
        z-index: 10;
        border-bottom:1px solid rgb(228,228,228);
    }

    .show-lang{
        display: flex !important;
    }
  }
  
  @media (min-width:1550px){

    .inner-contrast {
        width: 70%;
    }

    .nav-container{
        width: 70% !important;
    }
  }

  @media(min-width:2500px) {
    .inner-contrast {
        width: 45%;
    }


    .nav-container{
        width: 45% !important;
    }
  }

  @media(min-width:3500px) {
    .inner-contrast {
        width: 40%;
    }


    .nav-container{
        width: 40% !important;
    }
  }

  @media(min-width:4500px) {
    .inner-contrast {
        width: 30%;
    }


    .nav-container{
        width: 30% !important;
    }
  }

  @media(min-width:5000px) {
    .inner-contrast {
        width: 25%;
    }


    .nav-container{
        width: 25% !important;
    }
  }